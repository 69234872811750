import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {Product} from 'components/customer/Product/entity/Product';
import {
    QFPPreviewInterface,
    selectPreviewOptions,
    selectProduct,
    selectValidationData,
    ValidationDataInterface,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {AppState} from 'store/customer/storeSetup';
import {useRotatedFields} from 'components/customer/QFPRedux/helpers/useRotatedFields';
import {useQFPPreview} from 'hooks';
import {useGetQFPProductStructureQuery} from 'components/customer/Product/store/productApi';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {
    MaterialType,
    Material,
    MaterialEdge,
} from 'components/customer/Materials/entity';
import {getEdge} from 'components/customer/Materials/store/selectors/edgeSelector';
import {cloneDeep} from 'lodash';
import {mapEdgeBack} from 'components/customer/Materials/helper/edgeHelper';
import {mapMaterialBack} from 'components/customer/Materials/helper/materialHelper';

interface PreviewComponentInterface {
    isActive: boolean;
    index: number;
    product?: Product;
    previewOptions?: QFPPreviewInterface;
    validationData?: ValidationDataInterface;
    exteriorMaterial?: Material;
    edgeMaterial?: MaterialEdge;
}

const PreviewComponent = ({
    isActive,
    index,
    product,
    previewOptions,
    validationData,
    edgeMaterial,
    exteriorMaterial,
}: PreviewComponentInterface) => {
    const {data: validStructure} = useGetQFPProductStructureQuery({
        cabinetType: product.type,
    });
    const rotateEdgeFields = useRotatedFields(validStructure?.validStructure);

    const allPreviewOptions = useMemo(() => {
        if (typeof previewOptions === 'undefined') return {};

        const options = cloneDeep(previewOptions);
        options.extMaterial =
            exteriorMaterial && mapMaterialBack(exteriorMaterial);
        options.extEdge = edgeMaterial && mapEdgeBack(edgeMaterial);

        return options;
    }, [previewOptions, edgeMaterial, exteriorMaterial]);

    useQFPPreview(
        product,
        isActive,
        allPreviewOptions,
        index,
        validationData,
        rotateEdgeFields
    );

    return (
        <>
            <svg
                id="canvas-helper"
                style={{
                    height: 0,
                    width: 'auto',
                }}
            />
            <div>
                <strong>Panel Preview</strong>

                <div id={`canvas-container-${index}`} />
            </div>
        </>
    );
};

export default connect(
    (state: AppState, {index}: PreviewComponentInterface) => ({
        product: selectProduct(state, index),
        previewOptions: selectPreviewOptions(state, index),
        validationData: selectValidationData(state, index),
        exteriorMaterial: getMaterial(state, MaterialType.EXTERIOR, index),
        edgeMaterial: getEdge(state, MaterialType.EXTERIOR, index),
    })
)(PreviewComponent);
