import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react';
import {genericMessageHandler} from 'shared/helpers';
import {
    Door,
    Material,
    MaterialEdge,
    MaterialType,
} from 'components/customer/Materials/entity';
import {
    stripSizeFromString,
    MaterialEdgeSearchRequest,
} from 'components/customer/Materials/helper';
import {
    useLazySearchMaterialEdgesByCritriaQuery,
    MaterialSearchRequest,
} from 'components/customer/Materials/store/materialApi';
import {
    MaterialListItem,
    SearchLayout,
} from 'components/customer/Materials/SearchLayout';
import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import {useNotificationContext} from 'contexts';
import {useSearchParams} from 'react-router-dom';
import {useAppDispatch} from 'store/customer';
import {groupBy, isEqual} from 'lodash';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {connect} from 'react-redux';
import {edgeMapping} from 'components/customer/Materials/store/selectors/edgeSelector';
import {ColorSwatch} from 'components/customer/Materials/ColorSwatch';
import {Action} from '@reduxjs/toolkit';
import {
    useGetMatchedEdgeWithAbort,
    useSearchEdgeQueryWithAbort,
} from 'components/customer/Materials/helper/useMaterialsQueryWithAbort';
import {useItemHandler} from './helper/useItemHandler';

export interface EdgeSearchInterface {
    defaultPlaceholder: string;
    setEdge: (edge: MaterialEdge) => void;
    materialType: MaterialType;
    edge?: MaterialEdge;
    exteriorEdge?: MaterialEdge;
    edges?: MaterialEdge[];
    pageNumber?: number;
    edgesAdd?: (edges: MaterialEdge[], index?: number) => Action;
    edgesSet?: (edges: MaterialEdge[], index?: number) => Action;
    edgePageSet?: (page: number, index?: number) => Action;
    material?: Material;
    door?: Door;
    isDefaultLoaded?: boolean;
    isQFP?: boolean;
    index?: number;
    cabinetType?: string;
    searchEdge?: boolean;
    setSearchEdge?: (search: boolean, index?: number) => Action;
    isMiniBrowser?: boolean;
}

const EdgeSearch = ({
    defaultPlaceholder,
    setEdge,
    materialType,
    edge: selectedEdgeMaterial,
    exteriorEdge: selectedExteriorEdgeMaterial,
    edges: edgeMaterials,
    pageNumber,
    edgesAdd,
    edgesSet,
    edgePageSet,
    material: selectedMaterial,
    door: selectedDoor,
    isDefaultLoaded,
    isQFP,
    index,
    cabinetType,
    searchEdge,
    setSearchEdge,
    isMiniBrowser,
}: EdgeSearchInterface) => {
    const dispatch = useAppDispatch();
    const {notify} = useNotificationContext();

    const [searchParams] = useSearchParams();
    const [keywords, setKeywords] = useState('');
    const [placeholder, setPlaceholder] = useState(defaultPlaceholder);
    const [disabled, setDisabled] = useState(false);
    const {data: userProfile} = useGetUserQuery();
    const [showPagination, setShowPagination] = useState(false);

    const doorRef = useRef<Door>();
    const cancelSearchRequest = useRef(false);

    const edges = useMemo(() => {
        if (edgeMaterials.length) {
            return groupBy(edgeMaterials, 'material_type.name');
        }

        return {};
    }, [edgeMaterials]);

    const [search, {isLoading: isSearching, isFetching: isSearchFetching}] =
        useLazySearchMaterialEdgesByCritriaQuery();
    const {
        search: searchWithAbort,
        abort,
        isLoading,
        isFetching,
        isUninitialized,
    } = useSearchEdgeQueryWithAbort();
    const {
        search: getMatchedEdgeWithAbort,
        abort: abortGettingMatchedEdge,
        isLoading: isLoadingEdgeMatching,
        isFetching: isFetchingEdgeMatching,
    } = useGetMatchedEdgeWithAbort();

    const selectHandler = (edge: MaterialEdge) => {
        if (selectedEdgeMaterial && edge.id == selectedEdgeMaterial.id) return;

        setEdge(edge);
    };

    const copyFromMaterialEdge = () => {
        if (materialType == MaterialType.CARCASE) {
            dispatch(edgePageSet(1, index));
            selectHandler(selectedExteriorEdgeMaterial);
            if (keywords != '') setKeywords('');
        }
    };

    // Search by material name and type
    const searchEdges = useCallback(
        async (material: Material, setList = false) => {
            try {
                setDisabled(false);
                const searchCriteria: MaterialSearchRequest = {
                    keywords: selectedDoor.edge?.name
                        ? selectedDoor.edge?.name
                        : material.name,
                    materialType: String(material.type.id),
                    doorFilter: material.door_filter,
                    brand: material.brand.name,
                    finish: material.finish,
                };

                if (searchParams.has('product')) {
                    searchCriteria.cabinetType = parseInt(
                        searchParams.get('product')
                    );
                }

                if (typeof cabinetType != 'undefined') {
                    searchCriteria.cabinetType = parseInt(cabinetType);
                }

                const result = await getMatchedEdgeWithAbort(searchCriteria);
                const {isError, error} = result;

                if (isError) throw error;
                else {
                    const {
                        data: {data: searchEdges},
                    } = result;
                    if (!cancelSearchRequest.current) {
                        if (searchEdges.length) {
                            const currentEdge = searchEdges.find(
                                (edge) =>
                                    selectedEdgeMaterial &&
                                    edge.id == selectedEdgeMaterial.id
                            );
                            const edge = searchEdges[0];

                            if (
                                typeof currentEdge === 'undefined' ||
                                !isEqual(edge, currentEdge)
                            ) {
                                selectHandler(edge);
                            }

                            if (setList) {
                                dispatch(edgesSet(searchEdges, index));
                            }
                        }
                        dispatch(setSearchEdge(false, index));
                    }
                }
            } catch (e) {
                genericMessageHandler(notify, 'Could not fetch edge materials');
            } finally {
                setShowPagination(false);
                if (cancelSearchRequest.current)
                    cancelSearchRequest.current = false;
            }
        },
        [selectedDoor, isDefaultLoaded, selectedEdgeMaterial, cabinetType]
    );

    const setDefaultEdgeByDoor = useCallback(
        async (door: Door) => {
            if (
                door &&
                door.is_default_edge_type &&
                door.default_material_id &&
                door.default_material_id != -1 &&
                materialType == MaterialType.EXTERIOR
            ) {
                if (isEqual(doorRef.current, door)) {
                    if (door.is_default_edge_type_locked) {
                        setDisabled(true);
                    }
                } else {
                    doorRef.current = door;

                    const searchCriteria: MaterialEdgeSearchRequest = {
                        colour: door.default_material_id,
                        manufacturerId: userProfile.manufacturerId,
                    };

                    if (searchParams.has('product')) {
                        searchCriteria.cabinetType = parseInt(
                            searchParams.get('product')
                        );
                    }

                    if (typeof cabinetType != 'undefined') {
                        searchCriteria.cabinetType = parseInt(cabinetType);
                    }

                    const {
                        data: {data: edges},
                    } = await search(searchCriteria, true);

                    if (edges.length) {
                        selectHandler(edges[0]);
                        setShowPagination(false);

                        if (door.is_default_edge_type_locked) {
                            setDisabled(true);
                        } else {
                            setDisabled(false);
                        }
                    }
                }
                dispatch(setSearchEdge(false, index));
            } else {
                setDisabled(false);
                void searchEdges(selectedMaterial);
            }
            doorRef.current = door;
        },
        [selectedMaterial, isDefaultLoaded]
    );

    const searchEdgesByKeywords = async (
        keywords: string,
        currentPage = 1,
        pageSize = 20
    ) => {
        try {
            if (keywords == '') return;
            if (!cancelSearchRequest.current)
                cancelSearchRequest.current = true;

            const params: MaterialSearchRequest = {
                keywords: stripSizeFromString(keywords),
                currentPage,
                pageSize,
                doorFilter: selectedMaterial.door_filter,
            };

            if (searchParams.has('product')) {
                params.cabinetType = parseInt(searchParams.get('product'));
            }

            if (typeof cabinetType != 'undefined') {
                params.cabinetType = parseInt(cabinetType);
            }

            const {
                data: {data: edges, pagination} = {
                    data: [] as MaterialEdge[],
                    pagination: {page_count: 0},
                },
                isError,
                error,
            } = await searchWithAbort(params);

            if (
                isError &&
                error &&
                typeof error === 'object' &&
                'message' in error &&
                error?.message == 'Aborted'
            ) {
                if (cancelSearchRequest.current)
                    cancelSearchRequest.current = false;
                return;
            }

            if (edges.length) {
                const hasMultiplePages = pagination.page_count > 1;
                const lastPage = pageNumber == pagination.page_count;

                if (hasMultiplePages) {
                    setShowPagination(!lastPage);
                }
            }

            if (pageNumber == 1) {
                dispatch(edgesSet(edges, index));
            } else {
                dispatch(edgesAdd(edges, index));
            }
        } catch (e) {
            dispatch(edgesSet([], index));
            genericMessageHandler(notify, 'Could not fetch edge materials');
        } finally {
            if (cancelSearchRequest.current)
                cancelSearchRequest.current = false;
        }
    };

    const moreButtonHandler = useCallback(() => {
        const nextPage = pageNumber + 1;
        dispatch(edgePageSet(nextPage, index));
        void searchEdgesByKeywords(keywords, nextPage);
    }, [pageNumber, index, keywords]);

    const onFocusHandler = useCallback(() => {
        if (keywords == '' && selectedMaterial && selectedEdgeMaterial) {
            !disabled && setPlaceholder('Type here to begin search');
            void searchEdges(selectedMaterial, true);
        }
    }, [
        keywords,
        selectedMaterial,
        selectedEdgeMaterial,
        disabled,
        searchEdges,
    ]);

    const onBlurHandler = useCallback(
        () =>
            setPlaceholder(
                selectedEdgeMaterial ? selectedEdgeMaterial.displayName : 'Edge'
            ),
        [selectedEdgeMaterial]
    );

    const onSearchClearHandler = useCallback(() => {
        abort();
        abortGettingMatchedEdge();

        if (!cancelSearchRequest.current) cancelSearchRequest.current = true;
    }, [abort, abortGettingMatchedEdge]);

    const onSearchTextChangeHandler = useCallback((keywords: string) => {
        dispatch(edgePageSet(1, index));
        setKeywords(keywords);
    }, []);

    useEffect(() => {
        if (selectedEdgeMaterial) {
            setPlaceholder(selectedEdgeMaterial.displayName);
        }
    }, [selectedEdgeMaterial]);

    useEffect(() => {
        if (searchEdge && isDefaultLoaded) {
            void setDefaultEdgeByDoor(selectedDoor);
        }
    }, [searchEdge, isDefaultLoaded]);

    useEffect(() => {
        if (!searchEdge && materialType == MaterialType.EXTERIOR) {
            setDisabled(
                selectedDoor &&
                    selectedDoor.is_default_edge_type &&
                    selectedDoor.is_default_edge_type_locked
            );
        }
    }, [selectedDoor]);

    // Search by keywords
    useEffect(() => {
        dispatch(edgePageSet(1, index));
        void searchEdgesByKeywords(keywords);
    }, [keywords]);

    const edgeIndexTracker: number[] = [];

    const {addItemRef, handleKeyDown} = useItemHandler();

    return (
        <SearchLayout
            isMiniBrowser={isMiniBrowser}
            onSearchClear={onSearchClearHandler}
            data={selectedEdgeMaterial}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            className="material_edge_search"
            keywordsDefault={keywords}
            copyFromAction={
                materialType === MaterialType.CARCASE
                    ? copyFromMaterialEdge
                    : undefined
            }
            disabled={disabled}
            placeholder={placeholder}
            showPagination={showPagination}
            onClickPagination={moreButtonHandler}
            isLoading={
                isLoading ||
                isFetching ||
                isSearching ||
                isSearchFetching ||
                isLoadingEdgeMatching ||
                isFetchingEdgeMatching
            }
            onSearchTextChange={onSearchTextChangeHandler}
            label="Edge"
            inlineSearch={isQFP}
            fieldName={`edge_search_${index}`}
            inlinePreviewImage={
                isQFP && selectedEdgeMaterial && selectedEdgeMaterial.image
                    ? selectedEdgeMaterial.image
                    : ''
            }
            hasData={Object.keys(edges).length > 0 || !isUninitialized}>
            {({setShow, itemsRef, onKeyDown}) => {
                const handleClick = (edge: MaterialEdge) => () => {
                    setShow(false);
                    selectHandler(edge);
                };

                return Object.keys(edges).length ||
                    isUninitialized ||
                    isLoading ||
                    isFetching ? (
                    Object.keys(edges).map((key) => {
                        const colours = edges[String(key)].map((edge) => {
                            edgeIndexTracker.push(edge.id);
                            const ogIndex = edgeIndexTracker.findIndex(
                                (id) => id === edge.id
                            );
                            return (
                                <MaterialListItem
                                    key={edge.id}
                                    ref={addItemRef(ogIndex, itemsRef.current)}
                                    onKeyDown={handleKeyDown(
                                        ogIndex,
                                        onKeyDown
                                    )}
                                    tabIndex={0}
                                    $active={
                                        selectedEdgeMaterial &&
                                        edge.id == selectedEdgeMaterial.id
                                            ? true
                                            : false
                                    }
                                    onClick={handleClick(edge)}>
                                    <div>
                                        <ColorSwatch src={edge.image} />
                                    </div>
                                    <div>
                                        {parseHtmlString(
                                            edge.highlightedDisplayName
                                                ? edge.highlightedDisplayName
                                                : edge.displayName
                                        )}
                                    </div>
                                </MaterialListItem>
                            );
                        });

                        return (
                            <React.Fragment key={key}>
                                <MaterialListItem $title={true}>
                                    {parseHtmlString(key)}
                                </MaterialListItem>
                                {colours}
                            </React.Fragment>
                        );
                    })
                ) : keywords.length ? (
                    <MaterialListItem>
                        <strong>
                            No matches found, Please try updating search
                            keywords
                        </strong>
                    </MaterialListItem>
                ) : null;
            }}
        </SearchLayout>
    );
};

export default connect(edgeMapping)(EdgeSearch);
