import React, {
    useEffect,
    useState,
    useCallback,
    useMemo,
    CSSProperties,
} from 'react';
import {Form} from 'react-bootstrap';
import {Loader} from 'shared/helpers';
import {useSupplyDefaults} from 'components/customer/Room/helpers/useSupplyDefaults';
import {useFormikContext} from 'formik';
import {
    MoreInfoData,
    useConfirmationDialog,
    MoreInfo,
    DynamicRadio,
} from 'shared';
import {WizardComponent} from 'shared/components/Wizard/entity/WizardComponent';
import {useAppSelector} from 'store/customer';
import {
    selectDrawerStyle,
    selectHingeStyle,
} from 'components/customer/Room/store/roomSlice';
import {shallowEqual} from 'react-redux';
import {RoomFields} from 'components/customer/Room/RoomComponents';
import styled from 'styled-components';
import {HardwareOption} from 'shared/types/HardwareOption';
import {Flex} from 'shared/components/StyledComponents';
import {FormGroup} from 'shared/components/Forms/FormGroup';

interface SupplyDefaultsProps extends WizardComponent {
    settings?: boolean;
}

export interface RoomMessage {
    title?: string;
    text?: string;
}

export const SupplyDefaults = ({settings = false}: SupplyDefaultsProps) => {
    const {userProfile, supplyMethods, hardwareInclusions, adjLegs, loading} =
        useSupplyDefaults();
    const {values, setFieldValue} = useFormikContext<RoomFields>();
    const [disabled, setDisabled] = useState(true);
    const [drillOnlyDisable, setDrillOnlyDisable] = useState(false);
    const [message, setMessage] = useState<RoomMessage>({});
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();

    const hingeStyle = useAppSelector(selectHingeStyle, shallowEqual);
    const drawerStyle = useAppSelector(selectDrawerStyle, shallowEqual);

    const drillOnlyMessage = useCallback(() => {
        const specificMessage = {
            title: 'Option Unavailable',
            text: '<p>The following are not available with your hardware inclusions selection of Supply Hardware.</p>',
        };

        let isShowMessage = false;

        if (
            drawerStyle &&
            (drawerStyle.drillOnly ||
                drawerStyle.name.toLowerCase().indexOf('(drill only)') >= 0)
        ) {
            specificMessage.text += `<li>Drawer Style - ${drawerStyle.name}</li>`;
            isShowMessage = true;
        }

        if (
            hingeStyle &&
            hingeStyle.name.toLowerCase().indexOf('drill only') >= 0
        ) {
            specificMessage.text += `<li>Hinge style - ${hingeStyle.name}</li>`;
            isShowMessage = true;
        }

        specificMessage.text += `<p></p><p>Please update to different Hinge / Drawer style in order to activate the Supply Hardware option.</p>`;

        if (isShowMessage) {
            setMessage(specificMessage);
        }
    }, [hingeStyle, drawerStyle]);

    const setSupplyValues = useCallback((name: string, value: boolean) => {
        void setFieldValue(name, value ? 1 : 0);
    }, []);

    useEffect(() => {
        // if drill only hinge or drawer, disable assembly and hardware
        if (
            (hingeStyle &&
                hingeStyle.name.toLowerCase().indexOf('drill only') >= 0) ||
            (drawerStyle && drawerStyle.drillOnly)
        ) {
            void setFieldValue('hardwareInclusions', 0);
            void setFieldValue('supplyMethod', 0);
            setDisabled(true);
            setDrillOnlyDisable(true);
        } else {
            setDrillOnlyDisable(false);
            if (values.supplyMethod == 1) {
                setDisabled(true);
                void setFieldValue('hardwareInclusions', 1);
            } else {
                setDisabled(false);
            }
        }
    }, [values.supplyMethod, hingeStyle, drawerStyle]);

    // on change of message
    useEffect(() => {
        if (Object.keys(message).length) {
            showDialog({
                title: message.title,
                message: message.text,
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        name: 'Ok',
                        show: true,
                        action: () => {
                            setMessage({});
                            hideDialog();
                        },
                    },
                ],
            });
        }
    }, [message]);

    const getOptions = useCallback(
        (hardwareOptions: HardwareOption[]) => () =>
            Promise.resolve(hardwareOptions),
        []
    );

    const {flatpacksOnly, noHardware, noAdjustableLegs, isAssemblyOnly} =
        useMemo(() => {
            const {
                isSupplyMethodVisible,
                isHardwareInclusionVisible,
                isAdjustableLegsVisible,
                isAssemblyOnly,
            } = userProfile;
            return {
                flatpacksOnly:
                    (!isSupplyMethodVisible || !isHardwareInclusionVisible) &&
                    !isAssemblyOnly,
                noHardware: !isHardwareInclusionVisible && !isAssemblyOnly,
                noAdjustableLegs: !isAdjustableLegsVisible,
                isAssemblyOnly: isAssemblyOnly,
            };
        }, [
            userProfile.isSupplyMethodVisible,
            userProfile.isHardwareInclusionVisible,
            userProfile.isAdjustableLegsVisible,
            userProfile.isAssemblyOnly,
        ]);

    const commonStyle = useMemo<CSSProperties>(
        () => ({
            backgroundColor: '#e9ecef',
            padding: 15,
            borderRadius: 8,
            marginBottom: 0,
            flexDirection: 'column',
        }),
        []
    );

    return (
        <div className="relativePos">
            {dialog}
            <Loader loader={loading} relative={true} smallPaddingTop={true}>
                {settings ? <Separator /> : null}
                <Flex $gap="10px" $flexWrap="wrap">
                    <FormGroupLocal
                        asRow={false}
                        style={commonStyle}
                        controlId="includeAssemblyCost_">
                        <Form.Label>Product supply Method:</Form.Label>
                        <DynamicRadio
                            name="supplyMethod"
                            value={
                                userProfile.isSupplyMethodVisible
                                    ? !!values.supplyMethod
                                    : false
                            }
                            setFieldValue={setSupplyValues}
                            options={getOptions(supplyMethods)}
                            disabled={drillOnlyDisable}
                            showDisabledMessage={drillOnlyMessage}
                            yesOptionHidden={flatpacksOnly}
                            noOptionHidden={isAssemblyOnly}
                            isQFP={false}
                        />
                    </FormGroupLocal>
                    <FormGroupLocal
                        asRow={false}
                        style={commonStyle}
                        controlId="supplyHardware_">
                        <Form.Label>Hardware Inclusions:</Form.Label>
                        <DynamicRadio
                            name="hardwareInclusions"
                            value={
                                userProfile.isHardwareInclusionVisible
                                    ? !!values.hardwareInclusions
                                    : false
                            }
                            setFieldValue={setSupplyValues}
                            options={getOptions(hardwareInclusions)}
                            disabled={disabled}
                            showDisabledMessage={drillOnlyMessage}
                            yesOptionHidden={noHardware}
                            noOptionHidden={isAssemblyOnly}
                            isQFP={false}
                        />
                    </FormGroupLocal>
                    {userProfile.hasOwnProperty('isAddProductAvailable') &&
                    userProfile.isAddProductAvailable ? (
                        <FormGroupLocal
                            asRow={false}
                            style={commonStyle}
                            controlId="adjustableLegs">
                            <Form.Label>
                                Adjustable Legs:
                                {settings ? null : (
                                    <MoreInfo
                                        dynamicposition
                                        styleOverride={{
                                            display: 'inline',
                                            top: '2px',
                                        }}
                                        info={MoreInfoData.adjLegs}
                                    />
                                )}
                            </Form.Label>

                            <DynamicRadio
                                name="adjustableLegs"
                                value={
                                    userProfile.isAdjustableLegsVisible
                                        ? !!values.adjustableLegs
                                        : false
                                }
                                setFieldValue={setSupplyValues}
                                options={getOptions(adjLegs)}
                                disabled={false}
                                yesOptionHidden={noAdjustableLegs}
                                isQFP={false}
                            />
                        </FormGroupLocal>
                    ) : null}
                </Flex>
            </Loader>
        </div>
    );
};

const FormGroupLocal = styled(FormGroup)`
    min-width: 400px;
`;

const Separator = styled.hr`
    margin: 15px 0;
    background-color: #eee;
    border: none;
    height: 1px;
`;
