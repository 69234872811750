import {useMemo} from 'react';
import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import {useGetHardwareOptionsQuery} from 'components/customer/Product/store/productApi';
import {HardwareOption} from 'shared/types/HardwareOption';

export const useSupplyDefaults = () => {
    const {
        data: userProfile,
        isLoading: isLoadingUser,
        isFetching: isFetchingUser,
    } = useGetUserQuery();
    const {
        data: hardwareOptions,
        isLoading,
        isFetching,
    } = useGetHardwareOptionsQuery();

    const [supplyMethods, hardwareInclusions, adjLegs] = useMemo(() => {
        if (!hardwareOptions) {
            return [[], [], []];
        }

        const supplyMethods: HardwareOption[] = [];
        const hardwareInclusions: HardwareOption[] = [];
        const adjLegs: HardwareOption[] = [];

        hardwareOptions.forEach((option) => {
            if (option.assembly) {
                supplyMethods.push({...option});
            } else if (option.adjLegs) {
                adjLegs.push({...option});
            } else {
                hardwareInclusions.push({...option});
            }
        });

        return [supplyMethods, hardwareInclusions, adjLegs];
    }, [hardwareOptions]);

    return {
        loading: isLoading || isFetching || isLoadingUser || isFetchingUser,
        userProfile,
        supplyMethods,
        adjLegs,
        hardwareInclusions,
    };
};
