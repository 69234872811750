import React, {
    useState,
    useEffect,
    useLayoutEffect,
    useCallback,
    useMemo,
} from 'react';
import {object, string, number} from 'yup';
import {genericMessageHandler} from 'shared/helpers';
import {useNotificationContext, useJobContext, useAppContext} from 'contexts';
import {saveRoom} from 'service';
import {useConfirmationDialog} from 'shared';
import {useNavigate} from 'shared/reload/helper/useNavigate';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    invalidateProductDefault,
    invalidateProductDefaultByRoom,
    invalidateSidebarAndCost,
    useGetRoomProductsQuery,
} from 'components/customer/Product/store/productApi';
import {useMaterialValues} from 'components/customer/Product/helpers/useMaterialValues';
import {getMaterialRestrictions} from 'components/customer/Room/helpers/materialRestrictions';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {selectIsValid} from 'components/customer/Materials/store/materialSlice';
import {invalidateJob} from 'components/customer/Job/store/jobApi';
import useCustomColourValidationSchema from 'components/customer/Materials/helper/useCustomColourValidationSchema';
import {getRoomData} from 'components/customer/Room/helpers/getRoomData';
import {
    isRoomLoadingSet,
    roomSet,
    hingeStyleSet,
    drawerStyleSet,
    cabinetTopSet,
} from 'components/customer/Room/store/roomSlice';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {shallowEqual} from 'react-redux';
import {cloneDeep} from 'lodash';
import {useHardwareSelection} from 'components/customer/Room/helpers/useHardwareSelection';
import {getDoor} from 'components/customer/Materials/store/selectors/doorSelector';
import {useRoomPlannerLeftMenu} from 'components/customer/RoomPlanner/helper/useRoomPlannerLeftMenu';
import {Page} from 'store/customer/entity/Page';

export const initialValues = {
    cabinetId: null,
    name: '',
    description: '',

    // These values are for Hardware selection section
    hingeStyle: -1,
    drawerSystem: -1,
    cabinetTop: -1,
    // These values are for Hardware selection section

    // These values are for Exterior colour section
    exteriorType: 4,

    // If this is not -1 then the check for vinyl occurs
    exteriorBrandMaterial: -1,
    exteriorCategory: -1,
    // TODO

    exteriorStyle: -1,
    exteriorBrand: -1,
    exteriorFinish: -1,
    exteriorSubstrate: -1,
    exteriorColor: -1,
    exteriorEdgeBrand: -1,
    exteriorEdgeFinish: -1,
    exteriorEdgeColor: -1,
    // These values are for Exterior colour section

    // These values are for Carcase colour section
    carcaseType: -1,
    carcaseBrand: -1,
    carcaseFinish: -1,
    carcaseSubstrate: -1,
    carcaseColor: -1,
    carcaseEdgeBrand: -1,
    carcaseEdgeFinish: -1,
    carcaseEdgeColor: -1,
    exteriorColorHorizontal: 0,
    exteriorColorDoubleSided: 0,
    exteriorColorCustomName: '',
    carcaseColorHorizontal: 0,
    carcaseColorDoubleSided: 0,
    carcaseColorCustomName: '',
    // These values are for Carcase colour section

    // These values are for Supply Defaults
    supplyMethod: 1,
    hardwareInclusions: 0,
    adjustableLegs: 0,
    // These values are for Supply Defaults

    // These values are for gap size defaults
    gapDefaults: -1,
    doorGap: 0,
    drawerGap: 0,
    upperTopMargins: 0,
    upperBottomMargins: 0,
    baseTopMargins: 0,
    baseBottomMargins: 0,
    leftGap: 0,
    rightGap: 0,
    // These values are for gap size defaults

    // These values are for product size defaults
    productSizeDefaults: -1,
    toeKickHeight: 0,
    shelfSetBack: 0,
    baseCarcaseHeight: 0,
    baseCarcaseDepth: 0,
    upperCarcaseHeight: 0,
    upperCarcaseDepth: 0,
    tallCarcaseHeight: 0,
    tallCarcaseDepth: 0,
    // These values are for product size defaults

    // These values are for Advanced doors
    isAdvancedDoor: 0,
    doorBorderWidthTop: 0,
    doorBorderWidthLeft: 0,
    doorBorderWidthBottom: 0,
    doorBorderWidthRight: 0,
    doorAdvancedEdgeTop: 0,
    doorAdvancedEdgeBottom: 0,
    doorAdvancedEdgeLeft: 0,
    doorAdvancedEdgeRight: 0,
    doorAdvancedRailHeight: 0,
    doorAdvancedRailWidth: 0,
    doorAdvancedBarWidth: 0,
    // These values are for Advanced doors
};

const useRoomSchema = (
    /** @type {any[]} */ rooms,
    /** @type {any} */ roomId
) => {
    const {addCustomColourValidation} = useCustomColourValidationSchema();
    const selectedDoor = useAppSelector((state) => getDoor(state));
    const isNameUnique = async (/** @type {string} */ roomName) => {
        // Check if roomName exists and belongs to a different roomId
        const isDuplicate = rooms.some((room) => {
            return (
                room.name.trim().toLowerCase() ===
                    roomName.trim().toLowerCase() && room.id !== roomId
            );
        });

        if (isDuplicate) {
            return false; // Name is not unique
        }

        return true; // Name is unique
    };
    let roomSchema = object().shape({
        name: string()
            .required('Please enter a Room name')
            .test(
                'unique', // Test name
                'Room name already in use.', // Error message
                async (value) => await isNameUnique(value) // Async validation
            ),
        carcaseColor: number().moreThan(0, 'You must select a carcase colour'),
        carcaseEdgeColor: number().moreThan(
            0,
            'You must select a carcase edge colour'
        ),
        exteriorColor: number().moreThan(
            0,
            'You must select an exterior colour'
        ),
        exteriorEdgeColor: number().moreThan(
            0,
            'You must select an exterior edge colour'
        ),
        baseCarcaseHeight: number().moreThan(
            0,
            'You must enter the default base carcase height (in mm)'
        ),
        baseCarcaseDepth: number().moreThan(
            0,
            'You must enter the default base carcase depth (in mm)'
        ),
        tallCarcaseHeight: number().moreThan(
            0,
            'You must enter the default tall carcase height (in mm)'
        ),
        tallCarcaseDepth: number().moreThan(
            0,
            'You must enter the default tall carcase depth (in mm)'
        ),
        upperCarcaseHeight: number().moreThan(
            0,
            'You must enter the default upper carcase height (in mm)'
        ),
        upperCarcaseDepth: number().moreThan(
            0,
            'You must enter the default upper carcase depth (in mm)'
        ),
        toeKickHeight: number()
            .moreThan(0, 'You must enter the default toe kick height (in mm)')
            .max(
                245,
                'You must enter the toe kick height (in mm) less than or equal to 245'
            ),
        hingeStyle: number().moreThan(0, 'You must select a hinge series'),
        drawerSystem: number().moreThan(0, 'You must select a drawer system'),
    });

    // minimum validation
    const addMinimumValidation = (side, fieldName, minValue) => {
        roomSchema = roomSchema.shape({
            [side]: number().min(
                minValue,
                `${fieldName} must be at least ${minValue}mm`
            ),
        });
    };

    if (selectedDoor?.is_advanced) {
        const minimumBorderWidths = [
            {
                side: 'doorBorderWidthBottom',
                fieldName: 'Bottom Border Width',
                minWidth: selectedDoor?.minimum_border_width_bottom,
            },
            {
                side: 'doorBorderWidthTop',
                fieldName: 'Top Border Width',
                minWidth: selectedDoor?.minimum_border_width_top,
            },
            {
                side: 'doorBorderWidthLeft',
                fieldName: 'Left Border Width',
                minWidth: selectedDoor?.minimum_border_width_left,
            },
            {
                side: 'doorBorderWidthRight',
                fieldName: 'Right Border Width',
                minWidth: selectedDoor?.minimum_border_width_right,
            },
            {
                side: 'doorAdvancedRailWidth',
                fieldName: 'Rails Vertical Width',
                minWidth: selectedDoor?.minimum_rails_vertical_width,
            },
            {
                side: 'doorAdvancedRailHeight',
                fieldName: 'Rails Horizontal Height',
                minWidth: selectedDoor?.minimum_rails_horizontal_height,
            },
        ];

        minimumBorderWidths
            .filter(({minWidth}) => minWidth > 0)
            .forEach(({side, fieldName, minWidth}) => {
                addMinimumValidation(side, fieldName, minWidth);
            });
    }

    return addCustomColourValidation(roomSchema);
};

export const useRoom = (jobId, roomId) => {
    const dispatch = useAppDispatch();
    const setMaterials = useMaterialValues(Page.SETTING);
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();
    const {notify} = useNotificationContext();
    const [initialData, setInitialData] = useState({
        ...initialValues,
        ...{id: roomId},
    });

    const {hingeStyles, drawerSystems, cabinetTops} = useHardwareSelection();
    const {job, room, setRoom} = useJobContext();
    const {userProfile, setIsMobileMenuVisible} = useAppContext();
    const navigate = useNavigate();
    const {isRoomPlannerPage} = useRoomPlannerLeftMenu();
    const roomSchema = useRoomSchema(job.rooms, roomId);

    const areMaterialsValid = useAppSelector((state) => selectIsValid(state));
    // NOTE: 0 is exterior material type and 1 is carcase.
    // all room/product/setting data are in index 0
    const exterior = useAppSelector(
        (state) => getMaterial(state, 0, 0),
        shallowEqual
    );
    const carcase = useAppSelector(
        (state) => getMaterial(state, 1, 0),
        shallowEqual
    );

    const {data: products} = useGetRoomProductsQuery(
        {roomId: parseInt(`${room.id}`)},
        {
            skip: typeof room.id === 'undefined',
        }
    );

    const roomHasProducts = useMemo(
        () => products && products.length > 0,
        [products]
    );

    const showRoomMessage = useCallback(
        (response) => {
            let message =
                "<strong>IMPORTANT</strong> - The following items haven't been updated because of the following matters: <br/><br/>";

            if (
                response.unalteredCabinets &&
                response.unalteredCabinets.length > 0
            ) {
                message +=
                    '<strong>Items were using selections different from the original room defaults:</strong><br/>';
                response.unalteredCabinets.map((value, index) => {
                    message +=
                        '<strong> Item ' +
                        room.roomNumber +
                        '-' +
                        value.product +
                        ':</strong> ' +
                        value.productName +
                        '. ' +
                        value.fields +
                        " didn't match.<br/>";
                });
            }

            if (
                response.unalteredCabinetsMaterialError &&
                response.unalteredCabinetsMaterialError.length > 0
            ) {
                message +=
                    '<br/><strong>The selected material is too small to accommodate the maximum part sizes of your products:</strong><br/>';
                if (exterior) {
                    message +=
                        'Room Ext Material: <strong>' +
                        exterior.brand?.name +
                        ' ' +
                        exterior.finish +
                        ' ' +
                        exterior.name +
                        '</strong><br/>';

                    if (exterior?.length && exterior?.width) {
                        message +=
                            'Material Max Part Size: <strong>' +
                            exterior.length +
                            ' x ' +
                            exterior.width +
                            '</strong><br/>';
                    }
                }
                if (carcase) {
                    message +=
                        'Room Carc Material: <strong>' +
                        carcase.brand?.name +
                        ' ' +
                        carcase.finish +
                        ' ' +
                        carcase.name +
                        '</strong><br/>';

                    if (carcase?.length && carcase?.width) {
                        message +=
                            'Material Max Part Size: <strong>' +
                            carcase.length +
                            ' x ' +
                            carcase.width +
                            '</strong><br/>';
                    }
                }

                response.unalteredCabinetsMaterialError.map((value, index) => {
                    message +=
                        '<strong> Item ' +
                        room.roomNumber +
                        '-' +
                        value.product +
                        ':</strong> ' +
                        value.productName +
                        '<br/>';
                });
            }

            message +=
                '<br> Please check and amend these items if you wish for them to use the new room defaults.';

            showDialog({
                title: 'Room update complete',
                message: message,
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        name: 'Ok',
                        show: true,
                        action: () => {
                            genericMessageHandler(
                                notify,
                                response.messages,
                                'success'
                            );
                            navigate(
                                `/v2/job/${job.displayId}/room/${roomId}/dashboard`
                            );
                        },
                    },
                ],
            });
        },
        [exterior, carcase, roomId]
    );

    const doSaveRoom = async (formData) => {
        try {
            dispatch(isRoomLoadingSet(true));
            const response = await saveRoom(formData, job.id, roomId);

            setRoom({});
            dispatch(invalidateProductDefaultByRoom());
            dispatch(invalidateProductDefault());
            dispatch(invalidateSidebarAndCost());
            dispatch(invalidateJob());

            if (
                (response.unalteredCabinets.length > 0 ||
                    response.unalteredCabinetsMaterialError.length > 0) &&
                formData.get('room_status') != ''
            ) {
                showRoomMessage(response);
            } else {
                const roomId = response.roomId;

                genericMessageHandler(notify, response.messages, 'success');

                if (!isRoomPlannerPage) {
                    navigate(
                        `/v2/job/${job.displayId}/room/${roomId}/dashboard`
                    );
                }
            }
        } catch (e) {
            genericMessageHandler(notify, e);
        } finally {
            dispatch(isRoomLoadingSet(false));
        }
    };

    const loadDefautls = useCallback(({room, user}) => {
        // Note: can directly pass param to getRoomData, just
        // doing desctucturing for readability.
        const roomData = getRoomData({room, user});

        if (roomData.materials) {
            setMaterials(roomData.materials);
        }

        if (roomData.room) {
            dispatch(roomSet(roomData.room));
        }
    }, []);

    useEffect(() => {
        if (roomId) {
            if (Object.keys(room).length > 0) {
                loadDefautls({room});

                const restrictions = getMaterialRestrictions(room, {
                    hasCarcase: true,
                    hasExterior: true,
                    hasExteriorDoor: true,
                });

                if (restrictions.length > 0) {
                    showDialog({
                        title: 'Unavailable Options',
                        message: (
                            <>
                                <p>
                                    Following options are not available. Please
                                    update these options before saving.
                                </p>
                                <ul>
                                    {restrictions.map((restriction, index) => {
                                        return (
                                            <li key={index}>
                                                {parseHtmlString(restriction)}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                        ),
                        hideYesButton: true,
                        hideNoButton: true,
                        buttons: [
                            {
                                title: 'Ok',
                                name: 'Ok',
                                show: true,
                            },
                        ],
                    });
                }

                setInitialData({
                    cabinetId: null,
                    id: room.id,
                    name: room.name,
                    description: room.description,

                    hingeStyle: room.hingeStyle,
                    drawerSystem: room.drawerStyle,
                    cabinetTop: room.topPart,

                    exteriorType: room?.extMaterial
                        ? room.extMaterial.type
                        : parseInt(userProfile.defaultExtMaterialType),

                    exteriorBrandMaterial: room?.extMaterial
                        ? room.extMaterial.brand
                        : parseInt(userProfile.defaultExtBrand),
                    exteriorCategory: room.doorSuffixId,
                    exteriorStyle: room.doorStyle,
                    exteriorBrand: room?.extMaterial
                        ? room.extMaterial.brand
                        : parseInt(userProfile.defaultExtBrand),
                    exteriorFinish: room?.extMaterial
                        ? room.extMaterial.finish
                        : userProfile.defaultExtFinish,
                    exteriorSubstrate: room?.extMaterial
                        ? room.extMaterial.substrate
                        : userProfile.defaultExtSubstrate,
                    exteriorColor: room.extColour,
                    exteriorEdgeBrand: room?.extEdge
                        ? room.extEdge.brand
                        : userProfile.defaultExtEdgeBrand,
                    exteriorEdgeFinish: room?.extEdge
                        ? room.extEdge.finish
                        : userProfile.defaultExtEdgeFinish,
                    exteriorEdgeColor: room.extEdgeColour,
                    exteriorColorHorizontal: room.extHorGrain == 1,
                    exteriorColorDoubleSided: room.extDoubleSided == 1,
                    exteriorColorCustomName: room.extCustomColour?.trim(),

                    carcaseType: room?.carcMaterial
                        ? room.carcMaterial.type
                        : userProfile.defaultCarcMaterialType,
                    carcaseBrand: room?.carcMaterial
                        ? room.carcMaterial.brand
                        : userProfile.defaultCarcBrand,
                    carcaseFinish: room?.carcMaterial
                        ? room.carcMaterial.finish
                        : userProfile.defaultCarcFinish,
                    carcaseSubstrate: room?.carcMaterial
                        ? room.carcMaterial.substrate
                        : userProfile.defaultCarcSubstrate,
                    carcaseColor: room.carcColour,
                    carcaseEdgeBrand: room?.carcEdge
                        ? room.carcEdge.brand
                        : userProfile.defaultCarcEdgeBrand,
                    carcaseEdgeFinish: room?.carcEdge
                        ? room.carcEdge.finish
                        : userProfile.defaultCarcEdgeFinish,
                    carcaseEdgeColor: room.carcEdgeColour,
                    carcaseColorHorizontal: room.carcHorGrain == 1,
                    carcaseColorDoubleSided: room.carcDoubleSided == 1,
                    carcaseColorCustomName: room.carcCustomColour?.trim(),

                    supplyMethod: parseInt(room.includeAssembly),
                    hardwareInclusions: parseInt(room.excludeHardware) ? 0 : 1,
                    adjustableLegs: parseInt(room.adjustableLegs),

                    gapDefaults: parseInt(room.defaultGap),
                    doorGap: room.doorGap,
                    drawerGap: room.drawerGap,
                    upperTopMargins: room.upperTopGap,
                    upperBottomMargins: room.upperBottomGap,
                    baseTopMargins: room.baseTopGap,
                    baseBottomMargins: room.baseBottomGap,
                    leftGap: room.leftGap,
                    rightGap: room.rightGap,

                    productSizeDefaults: parseInt(room.defaultSize),
                    toeKickHeight: room.toeKickHeight,
                    shelfSetBack: room.shelfSetBack,
                    baseCarcaseHeight: room.baseHeight,
                    baseCarcaseDepth: room.baseDepth,
                    upperCarcaseHeight: room.upperHeight,
                    upperCarcaseDepth: room.upperDepth,
                    tallCarcaseHeight: room.tallHeight,
                    tallCarcaseDepth: room.tallDepth,

                    isAdvancedDoor: room.door.advanced == 1,
                    doorBorderWidthTop: room.doorBorderWidthTop,
                    doorBorderWidthLeft: room.doorBorderWidthLeft,
                    doorBorderWidthBottom: room.doorBorderWidthBottom,
                    doorBorderWidthRight: room.doorBorderWidthRight,
                    doorAdvancedEdgeTop: room.doorAdvancedEdgeTop,
                    doorAdvancedEdgeBottom: room.doorAdvancedEdgeBottom,
                    doorAdvancedEdgeLeft: room.doorAdvancedEdgeLeft,
                    doorAdvancedEdgeRight: room.doorAdvancedEdgeRight,
                    doorAdvancedRailHeight: room.doorAdvancedRailHeight,
                    doorAdvancedRailWidth: room.doorAdvancedRailWidth,
                    doorAdvancedBarWidth: room.doorAdvancedBarWidth,
                });
            }
        } else {
            loadDefautls({user: userProfile});
            let roomName = 'Room 1';
            const nameInStandardFormat =
                job &&
                job.rooms &&
                job.rooms.filter((room) =>
                    new RegExp(/^(Room)+\s+\d/).test(room.name)
                );

            if (nameInStandardFormat && nameInStandardFormat.length > 0) {
                const lastRoom = nameInStandardFormat.pop();
                roomName = lastRoom.name.replace(/(\d+)/g, (match, number) => {
                    return parseInt(number) + 1;
                });
            }

            setInitialData({
                ...initialValues,
                ...{
                    name: roomName,
                    hingeStyle: userProfile.defaultHinge,
                    drawerSystem: userProfile.defaultDrawer,
                    cabinetTop: userProfile.defaultRoomTop,

                    toeKickHeight: userProfile.defaultToeKickHeight,
                    shelfSetBack: userProfile.defaultShelfSetBack,

                    exteriorType: parseInt(userProfile.defaultExtMaterialType),
                    exteriorBrandMaterial: parseInt(
                        userProfile.defaultExtBrand
                    ),
                    exteriorCategory: userProfile.defaultDoorCategory,
                    exteriorStyle: userProfile.defaultDoor,

                    exteriorBrand: userProfile.defaultExtBrand,
                    exteriorFinish: userProfile.defaultExtFinish,
                    exteriorSubstrate: userProfile.defaultExtSubstrate,
                    exteriorColor: userProfile.defaultExtMaterial,
                    exteriorEdgeBrand: userProfile.defaultExtEdgeBrand,
                    exteriorEdgeFinish: userProfile.defaultExtEdgeFinish,
                    exteriorEdgeColor: userProfile.defaultExtEdge,
                    exteriorColorHorizontal:
                        userProfile.defaultExtHorGrain == 1,
                    exteriorColorDoubleSided:
                        userProfile.defaultExtDoubleSided == 1,
                    exteriorColorCustomName:
                        userProfile.defaultExtCustomColor?.trim(),

                    carcaseType: userProfile.defaultCarcMaterialType,
                    carcaseBrand: userProfile.defaultCarcBrand,
                    carcaseFinish: userProfile.defaultCarcFinish,
                    carcaseSubstrate: userProfile.defaultCarcSubstrate,
                    carcaseColor: userProfile.defaultCarcMaterial,
                    carcaseEdgeBrand: userProfile.defaultCarcEdgeBrand,
                    carcaseEdgeFinish: userProfile.defaultCarcEdgeFinish,
                    carcaseEdgeColor: userProfile.defaultCarcEdge,
                    carcaseColorHorizontal:
                        userProfile.defaultCarcHorGrain == 1,
                    carcaseColorDoubleSided:
                        userProfile.defaultCarcDoubleSided == 1,
                    carcaseColorCustomName:
                        userProfile.defaultCarcCustomColor?.trim(),

                    gapDefaults: userProfile.defaultGap,
                    doorGap: userProfile?.job?.room?.gapDefaults?.door_gap,
                    drawerGap: userProfile?.job?.room?.gapDefaults?.drawer_gap,
                    upperTopMargins:
                        userProfile?.job?.room?.gapDefaults?.upper_top_gap,
                    upperBottomMargins:
                        userProfile?.job?.room?.gapDefaults?.upper_bottom_gap,
                    baseTopMargins:
                        userProfile?.job?.room?.gapDefaults?.base_top_gap,
                    baseBottomMargins:
                        userProfile?.job?.room?.gapDefaults?.base_bottom_gap,
                    leftGap: userProfile?.job?.room?.gapDefaults?.left_gap,
                    rightGap: userProfile?.job?.room?.gapDefaults?.right_gap,

                    productSizeDefaults: userProfile.defaultSize,
                    baseCarcaseHeight:
                        userProfile?.job?.room?.sizeDefaults?.base_height,
                    baseCarcaseDepth:
                        userProfile?.job?.room?.sizeDefaults?.base_depth,
                    upperCarcaseHeight:
                        userProfile?.job?.room?.sizeDefaults?.upper_height,
                    upperCarcaseDepth:
                        userProfile?.job?.room?.sizeDefaults?.upper_depth,
                    tallCarcaseHeight:
                        userProfile?.job?.room?.sizeDefaults?.tall_height,
                    tallCarcaseDepth:
                        userProfile?.job?.room?.sizeDefaults?.tall_depth,

                    supplyMethod: parseInt(userProfile.defaultIncludeAssembly),
                    hardwareInclusions: parseInt(
                        userProfile.defaultIncludeHardware
                    ),
                    adjustableLegs: parseInt(userProfile.defaultAdjustableLegs),

                    isAdvancedDoor: userProfile.isDefaultDoorAdvanced,
                    doorBorderWidthTop: userProfile.defaultBorderWidthTop,
                    doorBorderWidthLeft: userProfile.defaultBorderWidthLeft,
                    doorBorderWidthBottom: userProfile.defaultBorderWidthBottom,
                    doorBorderWidthRight: userProfile.defaultBorderWidthRight,
                    doorAdvancedEdgeTop: userProfile.defaultAdvancedEdgeTop,
                    doorAdvancedEdgeBottom:
                        userProfile.defaultAdvancedEdgeBottom,
                    doorAdvancedEdgeLeft: userProfile.defaultAdvancedEdgeLeft,
                    doorAdvancedEdgeRight: userProfile.defaultAdvancedEdgeRight,
                    doorAdvancedRailHeight:
                        userProfile.defaultAdvancedRailHeight,
                    doorAdvancedRailWidth: userProfile.defaultAdvancedRailWidth,
                    doorAdvancedBarWidth: userProfile.defaultAdvancedBarWidth,
                },
            });
        }
    }, [roomId, room, userProfile]);

    useEffect(() => {
        if (initialData?.hingeStyle > 0 && hingeStyles) {
            const hingeStyle = hingeStyles?.find(
                (hinge) => hinge.id == initialData?.hingeStyle
            );
            if (hingeStyle) {
                dispatch(hingeStyleSet(hingeStyle));
            }
        }
    }, [initialData, hingeStyles]);

    useEffect(() => {
        if (initialData?.drawerSystem > 0 && drawerSystems) {
            const drawerSystem = drawerSystems?.find(
                (drawerSystem) => drawerSystem.id == initialData?.drawerSystem
            );
            if (drawerSystem) {
                dispatch(drawerStyleSet(drawerSystem));
            }
        }
    }, [initialData, drawerSystems]);

    useEffect(() => {
        if (initialData?.cabinetTop > 0 && cabinetTops) {
            const cabinetTop = cabinetTops?.find(
                (cabinetTop) => cabinetTop.id == initialData?.cabinetTop
            );
            if (cabinetTop) {
                dispatch(cabinetTopSet(cabinetTop));
            }
        }
    }, [initialData, cabinetTops]);

    const roomFormSubmitHandler = async (formValues) => {
        const values = cloneDeep(formValues);
        if (!areMaterialsValid) {
            showDialog({
                title: 'Unavailable Options',
                message: 'Please select valid materials',
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        title: 'Ok',
                        name: 'Ok',
                        show: true,
                    },
                ],
            });
            return;
        }

        const formData = new FormData();
        const forUpdatedKeys = [];
        const requiredKeys = {
            hingeStyle: 'Hinge Style',
            drawerSystem: 'Drawer System',
            cabinetTop: 'Cabinet Top',
            exteriorType: 'Exterior Type',
            exteriorColor: 'Exterior Colour',
            exteriorEdgeColor: 'Exterior Edge Colour',
            carcaseType: 'Carcase Type',
            carcaseColor: 'Carcase Colour',
            carcaseEdgeColor: 'Carcase Edge Colour',
            supplyMethod: 'Supply Method',
            hardwareInclusions: 'Hardware Inclusions',
            adjustableLegs: 'Adjustable Legs',
            exteriorColorHorizontal: 'Exterior Horizontal Grain',
            exteriorColorDoubleSided: 'Exterior Double Sided',
            carcaseColorHorizontal: 'Carcase Horizontal Grain',
            carcaseColorDoubleSided: 'Carcase Double Sided',
            exteriorColorCustomName: 'Exterior Custom Colour',
            carcaseColorCustomName: 'Carcase Custom Colour',
            exteriorStyle: 'Door Style',
        };
        const fieldMap = {
            name: 'room_name',
            status: 'room_status',
            description: 'room_desc',
            hingeStyle: 'room_ext_hinge_style',
            drawerSystem: 'room_drawer',
            cabinetTop: 'topPart',
            exteriorType: 'room_ext_material_type',
            exteriorStyle: 'room_ext_door_style',
            exteriorBrand: 'room_ext_material_brand',
            exteriorCategory: 'room_ext_door_category',
            exteriorFinish: 'room_ext_material_finish',
            exteriorSubstrate: 'room_ext_material_substrate',
            exteriorColor: 'room_ext_material_colour',
            exteriorEdgeBrand: 'room_ext_edge_brand',
            exteriorEdgeFinish: 'room_ext_edge_finish',
            exteriorEdgeColor: 'room_ext_edge_colour',
            exteriorColorCustomName: 'custom_colour_ext',
            carcaseType: 'room_carc_material_type',
            carcaseBrand: 'room_carc_material_brand',
            carcaseFinish: 'room_carc_material_finish',
            carcaseSubstrate: 'room_carc_material_substrate',
            carcaseColor: 'room_carc_material_colour',
            carcaseEdgeBrand: 'room_carc_edge_brand',
            carcaseColorCustomName: 'custom_colour_carc',
            carcaseEdgeFinish: 'room_carc_edge_finish',
            carcaseEdgeColor: 'room_carc_edge_colour',
            exteriorColorHorizontal: 'hor_grain_ext',
            exteriorColorDoubleSided: 'double_sided_ext',
            carcaseColorHorizontal: 'hor_grain_carc',
            carcaseColorDoubleSided: 'double_sided_carc',
            supplyMethod: 'room_include_assembly',
            hardwareInclusions: 'room_exclude_hardware',
            adjustableLegs: 'room_adjustable_legs',
            gapDefaults: 'room_gaps',
            doorGap: 'room_door_gap',
            drawerGap: 'room_drawer_gap',
            upperTopMargins: 'room_upper_top_gap',
            upperBottomMargins: 'room_upper_bottom_gap',
            baseTopMargins: 'room_base_top_gap',
            baseBottomMargins: 'room_base_bottom_gap',
            leftGap: 'room_left_gap',
            rightGap: 'room_right_gap',
            productSizeDefaults: 'room_sizes',
            toeKickHeight: 'room_toekick_height',
            shelfSetBack: 'room_shelf_set_back',
            baseCarcaseHeight: 'room_bheight',
            baseCarcaseDepth: 'room_bdepth',
            upperCarcaseHeight: 'room_uheight',
            upperCarcaseDepth: 'room_udepth',
            tallCarcaseHeight: 'room_theight',
            tallCarcaseDepth: 'room_tdepth',
            doorBorderWidthTop: 'room_door_border_width_top',
            doorBorderWidthBottom: 'room_door_border_width_bottom',
            doorBorderWidthLeft: 'room_door_border_width_left',
            doorBorderWidthRight: 'room_door_border_width_right',
            doorAdvancedEdgeTop: 'room_door_border_edge_top',
            doorAdvancedEdgeBottom: 'room_door_border_edge_bottom',
            doorAdvancedEdgeLeft: 'room_door_border_edge_left',
            doorAdvancedEdgeRight: 'room_door_border_edge_right',
            doorAdvancedRailHeight: 'room_door_rail_height',
            doorAdvancedRailWidth: 'room_door_rail_width',
            doorAdvancedBarWidth: 'room_door_bar_width',
        };
        Object.keys(fieldMap).forEach((key) => {
            if (
                roomId &&
                requiredKeys.hasOwnProperty(key) &&
                initialData[key] != values[key]
            ) {
                forUpdatedKeys.push(requiredKeys[key]);
            }
            if (fieldMap[key] == 'exteriorCategory') {
                if (!values[key]) values[key] = '';
            }
            if (fieldMap[key] == 'custom_colour_ext') {
                if (!values[key]) values[key] = '';
            }
            if (fieldMap[key] == 'custom_colour_carc') {
                if (!values[key]) values[key] = '';
            }
            // convert boolean/string boolean to numeric 1/0
            if (typeof values[key] == 'boolean') {
                values[key] = values[key] ? 1 : 0;
            }

            if (
                typeof values[key] == 'string' &&
                (values[key].toLowerCase() === 'false' ||
                    values[key].toLowerCase() === 'true')
            ) {
                values[key] = values[key].toLowerCase() === 'true' ? 1 : 0;
            }
            formData.append(fieldMap[key], values[key]);
        });

        if (values.is_exterior_material_vinyl) {
            formData.append('material_type_is_vinyl', 1);
        } else {
            formData.append('material_type_is_vinyl', 0);
        }
        formData.append('status', '');
        try {
            if (roomId) {
                if (forUpdatedKeys.length) {
                    showDialog({
                        title: 'Save Room',
                        message: `
                            <p>Changes to these properties have been made.</p>
                            <ul>
                            <li>
                            ${forUpdatedKeys.join('</li><li>')}
                            </li>
                            </ul>
                            ${
                                roomHasProducts
                                    ? `
                                <p>Would you like to make these changes to the existing products in this room also?</p>
                                <p>Please note that any other changes will not affect the existing products.</p>
                            `
                                    : ''
                            }
                        `,
                        hideNoButton: true,
                        hideYesButton: true,
                        buttons: [
                            {
                                name: 'Cancel',
                                show: true,
                                variant: 'danger',
                                alignLeft: true,
                            },
                            {
                                name: 'moreOptions',
                                show: roomHasProducts,
                                options: [
                                    {
                                        name: 'Update Room Only',
                                        action: () => {
                                            hideDialog();
                                            formData.set('room_status', '');
                                            doSaveRoom(formData);
                                        },
                                    },
                                    {
                                        name: 'Update Products Matching Room Defaults',
                                        action: () => {
                                            hideDialog();
                                            formData.set(
                                                'room_status',
                                                'changed'
                                            );
                                            doSaveRoom(formData);
                                        },
                                    },
                                ],
                            },
                            {
                                name: 'Update Room',
                                show: !roomHasProducts,
                                action: () => {
                                    hideDialog();
                                    formData.set('room_status', '');
                                    doSaveRoom(formData);
                                },
                            },
                            {
                                name: 'Update All Products',
                                show: roomHasProducts,
                                action: () => {
                                    formData.set('room_status', 'updateAll');
                                    doSaveRoom(formData);
                                },
                            },
                        ],
                    });
                } else {
                    showDialog({
                        title: 'Save Room',
                        message: `
                            <p>Any changes that have been made will not effect the existing products in this room.</p>
                            <p>Would you like to continue?</p>
                        `,
                        yes: async () => {
                            formData.set('room_status', '');
                            doSaveRoom(formData);
                        },
                        no: async () => {},
                    });
                }
            } else {
                doSaveRoom(formData);
            }
        } catch (e) {
            genericMessageHandler(notify, e);
        }
    };

    useLayoutEffect(() => {
        setIsMobileMenuVisible(false);
    }, []);

    return {
        initialData,
        roomSchema,
        roomFormSubmitHandler,
        dialog,
    };
};
