import React, {useCallback, useRef, useState} from 'react';
import {Col, Form, FormControl, Image, Row} from 'react-bootstrap';
import {MoreInfo, MoreInfoData} from 'shared';
import {useFormikContext} from 'formik';

import {ProductSizeDefaultsPreview} from 'assets';
import {shallowEqual} from 'react-redux';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {useGetProductSizeDefaultsQuery} from 'components/customer/Room/store/roomApi';
import {WizardComponent} from 'shared/components/Wizard/entity/WizardComponent';
import {
    SizeDefaultsFields,
    SizeDefaultField,
} from 'components/customer/Room/SizeDefaultField';
import {useProductSizeDefaultPreview} from 'components/customer/Room/helpers/useProductSizeDefaultPreview';
import styled from 'styled-components';
import {
    productSizeSet,
    selectProductSize,
} from 'components/customer/Room/store/roomSlice';
import {RoomFields} from 'components/customer/Room/RoomComponents';
import {useTabletSize} from 'shared/helpers/DeviceSize';
import SelectField from 'shared/components/SelectField';
import {FormGroup} from 'shared/components/Forms/FormGroup';

export const ProductSizeDefaults = ({}: WizardComponent) => {
    const dispatch = useAppDispatch();
    const {
        data: productSizes,
        isLoading,
        isFetching,
    } = useGetProductSizeDefaultsQuery();
    const imageReference = useRef<HTMLImageElement>();
    const imageContainerReference = useRef<HTMLDivElement>();
    const productSize = useAppSelector(selectProductSize, shallowEqual);

    const {values, setFieldValue, handleChange} =
        useFormikContext<RoomFields>();
    const {positions, setLoaded} = useProductSizeDefaultPreview(
        imageReference,
        imageContainerReference
    );
    const [focusedInput, setFocusedInput] = useState<SizeDefaultsFields>();
    const isTabletSize = useTabletSize();

    const sizeDefaultsSelectionHandler = useCallback(
        (name: string, value: string) => {
            void setFieldValue(name, value);

            const size = productSizes?.find((size) => String(size.id) == value);
            if (size) {
                dispatch(productSizeSet(size));
                void setFieldValue('baseCarcaseDepth', size.baseDepth, true);
                void setFieldValue('baseCarcaseHeight', size.baseHeight, true);
                void setFieldValue('tallCarcaseDepth', size.tallDepth, true);
                void setFieldValue('tallCarcaseHeight', size.tallHeight, true);
                void setFieldValue('upperCarcaseDepth', size.upperDepth, true);
                void setFieldValue(
                    'upperCarcaseHeight',
                    size.upperHeight,
                    true
                );
            }
        },
        [productSizes]
    );

    const onFocusHandler = useCallback(
        (field: SizeDefaultsFields) => () => setFocusedInput(field),
        []
    );

    const onImageLoad = useCallback(() => {
        setLoaded(true);
    }, []);

    return (
        <div className="relativePos">
            <Row>
                <Col md={isTabletSize ? 12 : 6} xs={12}>
                    <FormGroup controlId="productSizeDefaults_">
                        <Form.Label
                            column
                            md={isTabletSize ? 4 : {offset: 1, span: 4}}
                            xs={6}>
                            Product Size Defaults:
                            <MoreInfo info={MoreInfoData.productSizeDefaults} />
                        </Form.Label>
                        <Col
                            md={isTabletSize ? 5 : {offset: 1, span: 5}}
                            xs={6}>
                            <SelectField
                                name="productSizeDefaults"
                                placeholder={productSize?.name}
                                options={productSizes?.map(({id, name}) => ({
                                    label: name,
                                    value: String(id),
                                }))}
                                customOnChange={sizeDefaultsSelectionHandler}
                                isLoading={isLoading || isFetching}
                            />
                        </Col>
                    </FormGroup>
                    <SizeDefaultField
                        label="Toe Kick Height"
                        name="toeKickHeight"
                        field={SizeDefaultsFields.TOE_KICK_HEIGHT}
                        selectedField={focusedInput}
                        tabIndex={1}
                        setField={setFocusedInput}
                        moreInfo={MoreInfoData.toeKickHeight}
                    />
                    <SizeDefaultField
                        label="Shelf Setback"
                        name="shelfSetBack"
                        field={SizeDefaultsFields.SHELF_SETBACK}
                        selectedField={focusedInput}
                        tabIndex={2}
                        setField={setFocusedInput}
                        moreInfo={MoreInfoData.shelfSetBack}
                    />
                    <SizeDefaultField
                        label="Base Carcase Height"
                        name="baseCarcaseHeight"
                        field={SizeDefaultsFields.BASE_CARCASE_HEIGHT}
                        selectedField={focusedInput}
                        tabIndex={3}
                        setField={setFocusedInput}
                        moreInfo={MoreInfoData.baseCarcaseHeight}
                    />
                    <SizeDefaultField
                        label="Base Carcase Depth"
                        name="baseCarcaseDepth"
                        field={SizeDefaultsFields.BASE_CARCASE_DEPTH}
                        selectedField={focusedInput}
                        tabIndex={4}
                        setField={setFocusedInput}
                        moreInfo={MoreInfoData.baseCarcaseDepth}
                    />
                    <SizeDefaultField
                        label="Upper Carcase Height"
                        name="upperCarcaseHeight"
                        field={SizeDefaultsFields.UPPER_CARCASE_HEIGHT}
                        selectedField={focusedInput}
                        tabIndex={5}
                        setField={setFocusedInput}
                        moreInfo={MoreInfoData.upperCarcaseHeight}
                    />
                    <SizeDefaultField
                        label="Upper Carcase Depth"
                        name="upperCarcaseDepth"
                        field={SizeDefaultsFields.UPPER_CARCASE_DEPTH}
                        selectedField={focusedInput}
                        tabIndex={6}
                        setField={setFocusedInput}
                        moreInfo={MoreInfoData.upperCarcaseDepth}
                    />
                    <SizeDefaultField
                        label="Tall Carcase Height"
                        name="tallCarcaseHeight"
                        field={SizeDefaultsFields.TALL_CARCASE_HEIGHT}
                        selectedField={focusedInput}
                        tabIndex={7}
                        setField={setFocusedInput}
                        moreInfo={MoreInfoData.tallCarcaseHeight}
                    />
                    <SizeDefaultField
                        label="Tall Carcase Depth"
                        name="tallCarcaseDepth"
                        field={SizeDefaultsFields.TALL_CARCASE_DEPTH}
                        selectedField={focusedInput}
                        tabIndex={8}
                        setField={setFocusedInput}
                        moreInfo={MoreInfoData.tallCarcaseDepth}
                    />
                </Col>
                <Col>
                    <div
                        ref={imageContainerReference}
                        className="product-size-preview-container">
                        <Image
                            src={String(ProductSizeDefaultsPreview)}
                            ref={imageReference}
                            alt="Product size preview"
                            onLoad={onImageLoad}
                        />

                        {positions ? (
                            <div className="inputs">
                                <FormInput
                                    className="preiew-input"
                                    $top={positions.upperCarcaseHeight.y}
                                    $left={positions.upperCarcaseHeight.x}
                                    $active={
                                        focusedInput ==
                                        SizeDefaultsFields.UPPER_CARCASE_HEIGHT
                                    }
                                    id="upper-carcase-height"
                                    value={values.upperCarcaseHeight}
                                    name="upperCarcaseHeight"
                                    onChange={handleChange}
                                    onFocus={onFocusHandler(
                                        SizeDefaultsFields.UPPER_CARCASE_HEIGHT
                                    )}
                                    tabIndex={9}
                                />
                                <FormInput
                                    className="preiew-input"
                                    $top={positions.upperCarcaseDepth.y}
                                    $left={positions.upperCarcaseDepth.x}
                                    $active={
                                        focusedInput ==
                                        SizeDefaultsFields.UPPER_CARCASE_DEPTH
                                    }
                                    id="upper-carcase-depth"
                                    value={values.upperCarcaseDepth}
                                    name="upperCarcaseDepth"
                                    onChange={handleChange}
                                    onFocus={onFocusHandler(
                                        SizeDefaultsFields.UPPER_CARCASE_DEPTH
                                    )}
                                    tabIndex={10}
                                />
                                <FormInput
                                    $top={positions.tallCarcaseHeight.y}
                                    $left={positions.tallCarcaseHeight.x}
                                    $active={
                                        focusedInput ==
                                        SizeDefaultsFields.TALL_CARCASE_HEIGHT
                                    }
                                    className="preiew-input"
                                    id="tall-carcase-height"
                                    value={values.tallCarcaseHeight}
                                    name="tallCarcaseHeight"
                                    onChange={handleChange}
                                    onFocus={onFocusHandler(
                                        SizeDefaultsFields.TALL_CARCASE_HEIGHT
                                    )}
                                    tabIndex={11}
                                />
                                <FormInput
                                    $top={positions.tallCarcaseDepth.y}
                                    $left={positions.tallCarcaseDepth.x}
                                    $active={
                                        focusedInput ==
                                        SizeDefaultsFields.TALL_CARCASE_DEPTH
                                    }
                                    className="preiew-input"
                                    id="tall-carcase-depth"
                                    value={values.tallCarcaseDepth}
                                    name="tallCarcaseDepth"
                                    onChange={handleChange}
                                    onFocus={onFocusHandler(
                                        SizeDefaultsFields.TALL_CARCASE_DEPTH
                                    )}
                                    tabIndex={12}
                                />
                                <FormInput
                                    $top={positions.baseCarcaseDepth.y}
                                    $left={positions.baseCarcaseDepth.x}
                                    $active={
                                        focusedInput ==
                                        SizeDefaultsFields.BASE_CARCASE_DEPTH
                                    }
                                    className="preiew-input"
                                    id="base-carcase-depth"
                                    value={values.baseCarcaseDepth}
                                    name="baseCarcaseDepth"
                                    onChange={handleChange}
                                    onFocus={onFocusHandler(
                                        SizeDefaultsFields.BASE_CARCASE_DEPTH
                                    )}
                                    tabIndex={13}
                                />
                                <FormInput
                                    $top={positions.shelfSetBack.y}
                                    $left={positions.shelfSetBack.x}
                                    $active={
                                        focusedInput ==
                                        SizeDefaultsFields.SHELF_SETBACK
                                    }
                                    className="preiew-input"
                                    id="shelf-setback"
                                    value={values.shelfSetBack}
                                    name="shelfSetBack"
                                    onChange={handleChange}
                                    onFocus={onFocusHandler(
                                        SizeDefaultsFields.SHELF_SETBACK
                                    )}
                                    tabIndex={14}
                                />
                                <FormInput
                                    $top={positions.baseCarcaseHeight.y}
                                    $left={positions.baseCarcaseHeight.x}
                                    $active={
                                        focusedInput ==
                                        SizeDefaultsFields.BASE_CARCASE_HEIGHT
                                    }
                                    className="preiew-input"
                                    id="base-carcase-height"
                                    value={values.baseCarcaseHeight}
                                    name="baseCarcaseHeight"
                                    onChange={handleChange}
                                    onFocus={onFocusHandler(
                                        SizeDefaultsFields.BASE_CARCASE_HEIGHT
                                    )}
                                    tabIndex={15}
                                />
                                <FormInput
                                    $top={positions.toeKickHeight.y}
                                    $left={positions.toeKickHeight.x}
                                    $active={
                                        focusedInput ==
                                        SizeDefaultsFields.TOE_KICK_HEIGHT
                                    }
                                    className="preiew-input"
                                    id="toe-kick-height"
                                    value={values.toeKickHeight}
                                    name="toeKickHeight"
                                    onChange={handleChange}
                                    onFocus={onFocusHandler(
                                        SizeDefaultsFields.TOE_KICK_HEIGHT
                                    )}
                                    tabIndex={16}
                                />
                            </div>
                        ) : null}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const FormInput = styled(FormControl)<{
    $active: boolean;
    $top: number;
    $left: number;
}>`
    top: ${({$top}) => $top}px;
    left: ${({$left}) => $left}px;
    box-shadow: ${({$active}) =>
        $active ? '0 0 0 0.2rem rgba(0, 123, 255, .25)' : 'none'};
`;
