import React, {useRef, useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useFormikContext} from 'formik';

import {MaterialFormFieldOptions} from 'shared/types/materials';

import {Search} from 'components/customer/Materials/Search';
import {ColorSwatchSelected} from 'components/customer/Room/ColorSwatches';
import {MaterialType} from 'components/customer/Materials/entity';
import {Page} from 'store/customer/entity/Page';
import {useAppDispatch} from 'store/customer';
import {setProductDefaultTabErrorMapping} from 'components/customer/Room/store/roomErrorSlice';
import {WizardComponent} from 'shared/components/Wizard/entity/WizardComponent';
import {RoomFields} from 'components/customer/Room/RoomComponents';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import styled from 'styled-components';
import AdvancedMaterials from 'components/customer/AdvancedMaterials/AdvancedMaterials';

export interface MaterialSearchStatus {
    carcase: boolean;
    exterior: boolean;
}

interface ColourSearchInterface extends WizardComponent {
    materialType: MaterialType;
    materialPage: Page;
    eventKey: number;
    isMiniEditor?: boolean;
}

const ColourSearch = ({
    materialType,
    materialPage,
    eventKey,
    isMiniEditor = false,
}: ColourSearchInterface): JSX.Element => {
    const dispatch = useAppDispatch();
    const isSmallDevice = isDeviceSmall();

    const {values} = useFormikContext<RoomFields>();

    const field = useRef<MaterialFormFieldOptions>({
        options: {
            horizontalGrainFieldName:
                materialType == MaterialType.EXTERIOR
                    ? 'exteriorColorHorizontal'
                    : 'carcaseColorHorizontal',
            doubleSidedFieldName:
                materialType == MaterialType.EXTERIOR
                    ? 'exteriorColorDoubleSided'
                    : 'carcaseColorDoubleSided',
            customColourFieldName:
                materialType == MaterialType.EXTERIOR
                    ? 'exteriorColorCustomName'
                    : 'carcaseColorCustomName',
        },
    });

    useEffect(() => {
        let payload = {};
        if (materialType == MaterialType.EXTERIOR)
            payload = {
                [eventKey]: {
                    tabName: 'exteriorColourSelections',
                    fields: ['exteriorColorCustomName'],
                },
            };
        else
            payload = {
                [eventKey]: {
                    tabName: 'carcaseColourSelections',
                    fields: ['carcaseColorCustomName'],
                },
            };
        dispatch(setProductDefaultTabErrorMapping(payload));
    }, [eventKey, materialType]);

    return (
        <div className="relativePos">
            <Row>
                <Col md={isMiniEditor ? 12 : 3}>
                    <ColorSwatchContainer
                        $isMobile={isSmallDevice}
                        $isMiniBrowser={isMiniEditor}>
                        <ColorSwatchSelected
                            containerClass=".col-md-2"
                            isExterior={materialType == MaterialType.EXTERIOR}
                            horizontal={
                                materialType == MaterialType.EXTERIOR
                                    ? values.exteriorColorHorizontal
                                    : values.carcaseColorHorizontal
                            }
                        />
                        <ColorBrowserButtonContainer>
                            <AdvancedMaterials
                                materialType={materialType}
                                hasDoors={materialType == MaterialType.EXTERIOR}
                                page={materialPage}
                            />
                        </ColorBrowserButtonContainer>
                    </ColorSwatchContainer>
                </Col>
                <Search
                    isMiniBrowser={isMiniEditor}
                    hasDoor={materialType == MaterialType.EXTERIOR}
                    field={field.current}
                    materialType={materialType}
                    page={materialPage}
                />
            </Row>
        </div>
    );
};

export const ColorSwatchContainer = styled.div<{
    $isMobile?: boolean;
    $isMiniBrowser?: boolean;
}>`
    display: ${({$isMobile, $isMiniBrowser}) =>
        $isMobile || $isMiniBrowser ? 'flex' : 'initial'};
    justify-content: center;
    margin-bottom: ${({$isMobile}) => ($isMobile ? '10px' : '0')};

    ${({$isMiniBrowser}) => {
        if ($isMiniBrowser) {
            return `
                flex-direction: column;
                align-items: center;
            `;
        }
    }}

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        gap: 10px;
        justify-content: space-between;
        align-items: flex-end;
    }
`;

const ColorBrowserButtonContainer = styled.div`
    margin-top: 25px;
`;

export default ColourSearch;
