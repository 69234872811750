import {PreviewFormValues} from 'Preview3D/types';
import {RoomPlannerFormValues} from 'components/customer/RoomPlanner/types';

type FormValues = {
    height: number;
    depth: number;
    width: number;
    rangehoodOpeningHeight: number;
};

export const DEFAULT_VENT_WIDTH = 176.75;
export const DEFAULT_VENT_FRONT_WIDTH = 203;

export const getRangehoodFormValues = (values: PreviewFormValues) => {
    return {
        height: Number(values.cabinet_height),
        depth: Number(values.cabinet_depth),
        width: Number(values.cabinet_width),
        rangehoodOpeningHeight: Number(values.rangehood_opening_height) || 0,
    };
};

export const generateRangehoodValues = (
    values: RoomPlannerFormValues,
    formValues?: FormValues
) => {
    const carcaseThickness = values.cabinet_carc_thickness;

    return {
        verticalSetback: formValues.rangehoodOpeningHeight,
        ventPositionZ: formValues.rangehoodOpeningHeight,
        ventWidth: DEFAULT_VENT_WIDTH,
        ventHeight: formValues.height - formValues.rangehoodOpeningHeight,
        ventRightPositionX: formValues.width / 2 + DEFAULT_VENT_FRONT_WIDTH / 2,
        ventSidePositionY: formValues.depth - carcaseThickness,
        ventLeftPositionX: formValues.width / 2 - DEFAULT_VENT_FRONT_WIDTH / 2,
        ventFrontPositionY:
            formValues.depth - DEFAULT_VENT_WIDTH - carcaseThickness,
    };
};
