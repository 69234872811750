import {
    Door,
    Material,
    MaterialEdge,
    Suffix,
} from 'components/customer/Materials/entity';
import {mapMaterial} from 'components/customer/Materials/helper/materialHelper';
import {mapMaterialEdge} from 'components/customer/Materials/helper/edgeHelper';
import {Product} from 'components/customer/Product/entity/Product';
import {
    transformDisplayNameEdge,
    transformDisplayName,
} from 'components/customer/Materials/helper';
import {cloneDeep} from 'lodash';

export interface ProductMaterials {
    exterior?: Material;
    edgeExterior?: MaterialEdge;
    carcase?: Material;
    edgeCarcase?: MaterialEdge;
    door?: Door;
}

export const getMaterialsWithRestrictions = (
    defaultProduct: Partial<Product>,
    typeChange: boolean
) => {
    const product = cloneDeep(defaultProduct);

    if (product && product.available_options_details) {
        if (
            !product.available_options_details.is_material_available ||
            typeChange
        ) {
            if (product.available_options_details.exterior_material != null) {
                product.ext_material =
                    product.available_options_details.exterior_material;
            }
        }

        if (
            !product.available_options_details.is_edge_available ||
            typeChange
        ) {
            if (
                product.available_options_details.exterior_edge_material != null
            ) {
                product.ext_edge =
                    product.available_options_details.exterior_edge_material;
            }
        }

        if (
            !product.available_options_details.is_door_available ||
            typeChange
        ) {
            if (product.available_options_details.door != null) {
                product.door = product.available_options_details.door;
            }
        }
    }

    const materials = getMaterials(product);

    return materials;
};

export const getMaterials = (product: Partial<Product>) => {
    const materials: ProductMaterials = {};

    materials.exterior = yamExteriorMapper(product);
    materials.edgeExterior = yamEdgeMapper(product);
    materials.carcase = yamExteriorMapper(product, false);
    materials.edgeCarcase = yamEdgeMapper(product, false);
    materials.door = yamDoorMapper(product);

    return materials;
};

const yamExteriorMapper = (product: Partial<Product>, isExterior = true) => {
    let legacyMaterial;

    if (isExterior && product.ext_material) {
        legacyMaterial = product.ext_material;
    } else if (!isExterior && product.carc_material) {
        legacyMaterial = product.carc_material;
    }

    if (legacyMaterial) {
        const exterior = mapMaterial(legacyMaterial);
        exterior.id = parseInt(exterior.id);

        if (typeof legacyMaterial.brand == 'object') {
            exterior.brand = legacyMaterial.brand;
        } else {
            exterior.brand_id = legacyMaterial.brand;
            exterior.brand.name =
                legacyMaterial.brand_name ?? legacyMaterial.brandName;
        }

        if (legacyMaterial.hasOwnProperty('prefixId')) {
            exterior.prefix_id = Number(legacyMaterial.prefixId);
            exterior.prefix.id = Number(legacyMaterial.prefixId);
        } else {
            exterior.prefix_id = Number(legacyMaterial.prefix_id);
            exterior.prefix.id = Number(legacyMaterial.prefix_id);
        }

        if (legacyMaterial.hasOwnProperty('prefixName')) {
            exterior.prefix_name = legacyMaterial.prefixName;
            exterior.prefix.name = legacyMaterial.prefixName;
        } else {
            exterior.prefix_name = legacyMaterial.prefix_name;
            exterior.prefix.name = legacyMaterial.prefix_name;
        }

        if (legacyMaterial.hasOwnProperty('type_id')) {
            exterior.type_id = parseInt(legacyMaterial.type_id);
            exterior.type.id = parseInt(legacyMaterial.type_id);
        }

        if (legacyMaterial.hasOwnProperty('type')) {
            exterior.type_id = Number(legacyMaterial.type);
            exterior.type.id = Number(legacyMaterial.type);
        }

        if (legacyMaterial.hasOwnProperty('typeName')) {
            exterior.type.name = legacyMaterial.typeName;
        } else {
            exterior.type.name = legacyMaterial.type_name;
        }

        if (legacyMaterial.hasOwnProperty('door_filter')) {
            exterior.door_filter = legacyMaterial.door_filter;
        }

        if (legacyMaterial.hasOwnProperty('doorFilter')) {
            exterior.door_filter = legacyMaterial.doorFilter;
        }

        if (legacyMaterial.hasOwnProperty('length')) {
            exterior.length = parseInt(legacyMaterial.length);
        } else {
            exterior.length = legacyMaterial.default_length;
        }

        if (legacyMaterial.hasOwnProperty('width')) {
            exterior.width = parseInt(legacyMaterial.width);
        } else {
            exterior.width = legacyMaterial.default_width;
        }

        if (legacyMaterial.hasOwnProperty('is_double_sided')) {
            exterior.is_double_sided = Boolean(
                Number(legacyMaterial.is_double_sided)
            );
        } else if (legacyMaterial.hasOwnProperty('double_sided')) {
            exterior.is_double_sided = legacyMaterial.double_sided == 1;
        } else {
            exterior.is_double_sided = Boolean(
                Number(legacyMaterial.doubleSided)
            );
        }

        if (legacyMaterial.hasOwnProperty('is_grained')) {
            exterior.is_grained = Boolean(Number(legacyMaterial.is_grained));
        } else if (legacyMaterial.hasOwnProperty('hor_grain')) {
            exterior.is_grained = legacyMaterial.hor_grain == 1;
        } else {
            exterior.is_grained = Boolean(Number(legacyMaterial.horGrain));
        }

        exterior.is_custom_colour =
            legacyMaterial.name.toLowerCase().indexOf('custom colour') > -1;

        exterior.displayName = transformDisplayName(exterior).displayName;
        exterior.image = `/uploads/gocabinet_materials/${legacyMaterial.image}`;

        return exterior;
    }
};

const yamEdgeMapper = (product: Partial<Product>, isExterior = true) => {
    let legacyEdge;

    if (isExterior && product.ext_edge) {
        legacyEdge = product.ext_edge;
    } else if (!isExterior && product.carc_edge) {
        legacyEdge = product.carc_edge;
    }

    if (legacyEdge) {
        const edge = mapMaterialEdge(legacyEdge);

        if (typeof legacyEdge.brand == 'object') {
            edge.brand = legacyEdge.brand;
        } else {
            edge.brand_id = parseInt(legacyEdge.brand);
            edge.brand.name = legacyEdge.brand_name ?? legacyEdge.brandName;
        }

        if (legacyEdge.hasOwnProperty('door_filter')) {
            edge.door_filter = legacyEdge.door_filter;
        }

        if (legacyEdge.hasOwnProperty('doorFilter')) {
            edge.door_filter = legacyEdge.doorFilter;
        }

        if (legacyEdge.hasOwnProperty('door_filter')) {
            edge.door_filter = legacyEdge.door_filter;
        }

        if (legacyEdge.hasOwnProperty('doorFilter')) {
            edge.door_filter = legacyEdge.doorFilter;
        }

        edge.material_type_id = parseInt(legacyEdge.type);

        edge.displayName = transformDisplayNameEdge(edge).displayName;
        edge.image = `/uploads/gocabinet_materials/${legacyEdge.image}`;

        return edge;
    }
};

const yamDoorMapper = (product: Partial<Product>): Door => {
    if (product.door) {
        const legacyDoor = product.door;

        const door = {
            is_hidden: legacyDoor.hidden,
            id: legacyDoor.id,
            suffix_id: legacyDoor.suffix_id,
            suffix_name: legacyDoor.hasOwnProperty('suffix_name')
                ? legacyDoor.suffix_name
                : legacyDoor.suffix,
            name: legacyDoor.name,
            filter_name: legacyDoor.filter_name,
            is_advanced: legacyDoor.hasOwnProperty('is_advanced')
                ? legacyDoor.is_advanced
                : legacyDoor.advanced == 1,
            default_material_id:
                legacyDoor.hasOwnProperty('default_colour') &&
                legacyDoor.default_colour != null
                    ? parseInt(legacyDoor.default_colour)
                    : null,
            is_default_edge_finish: legacyDoor.set_as_default_edge_finish == 1,
            is_locked_edge_finish: legacyDoor.lock_default_edge_finish == 1,
            default_edge_finish_bottom: legacyDoor.default_edge_finish_bottom,
            default_edge_finish_left: legacyDoor.default_edge_finish_left,
            default_edge_finish_right: legacyDoor.default_edge_finish_right,
            default_edge_finish_top: legacyDoor.default_edge_finish_top,
            default_edge_finish_join: legacyDoor.default_edge_finish_join,
            is_default_edge_type:
                typeof legacyDoor.set_as_default_edge_type == 'number'
                    ? legacyDoor.set_as_default_edge_type == 1
                    : legacyDoor.set_as_default_edge_type,
            is_default_edge_type_locked:
                typeof legacyDoor.lock_default_edge_type == 'number'
                    ? legacyDoor.lock_default_edge_type == 1
                    : legacyDoor.lock_default_edge_type,
            minimum_width: legacyDoor.minimum_width,
            maximum_width: legacyDoor.maximum_width,
            minimum_height: legacyDoor.minimum_height,
            maximum_height: legacyDoor.maximum_height,
            minimum_drawer_height: legacyDoor.minimum_drawer_height,
            minimum_border_width_top:
                legacyDoor?.minimumBorderWidthTop ||
                legacyDoor.minimum_border_width_top,
            minimum_border_width_bottom:
                legacyDoor?.minimumBorderWidthBottom ||
                legacyDoor.minimum_border_width_bottom,
            minimum_border_width_left:
                legacyDoor?.minimumBorderWidthLeft ||
                legacyDoor.minimum_border_width_left,
            minimum_border_width_right:
                legacyDoor?.minimumBorderWidthRight ||
                legacyDoor.minimum_border_width_right,
            default_border_width_top: legacyDoor.default_border_width_top,
            default_border_width_bottom: legacyDoor.default_border_width_bottom,
            default_border_width_left: legacyDoor.default_border_width_left,
            default_border_width_right: legacyDoor.default_border_width_right,
            minimum_distance_top_bottom: legacyDoor.minimum_distance_top_bottom,
            minimum_distance_left_right: legacyDoor.minimum_distance_left_right,
            minimum_rails_horizontal_height:
                legacyDoor?.minimumRailsHorizontalHeight ||
                legacyDoor.minimum_rails_horizontal_height,
            maximum_rails_horizontal_height:
                legacyDoor.maximum_rails_horizontal_height,
            minimum_rails_vertical_width:
                legacyDoor?.minimumRailsVerticalWidth ||
                legacyDoor.minimum_rails_vertical_width,
            maximum_rails_vertical_width:
                legacyDoor.maximum_rails_vertical_width,
            default_rails_horizontal_height:
                legacyDoor.default_rails_horizontal_height,
            default_rails_vertical_width:
                legacyDoor.default_rails_vertical_width,
            image: {
                name: `/uploads/images/Door - Drawer Face/${legacyDoor.image_name}`,
            },
            suffix: {
                id: legacyDoor.suffix_id,
                name: legacyDoor.hasOwnProperty('suffix_name')
                    ? legacyDoor.suffix_name
                    : legacyDoor.suffix,
            } as Suffix,
            is_single_part_collective: legacyDoor?.is_single_part_collective,
            is_single_part_individual: legacyDoor?.is_single_part_individual,
            is_single_part_reduced_rails:
                legacyDoor?.is_single_part_reduced_rails,
        };

        if (legacyDoor.hasOwnProperty('filterName')) {
            door.filter_name = legacyDoor.filterName;
        }

        return door;
    }
};
