import {PreviewFormValues} from 'Preview3D/types';
import {makeZeroIfUndefined} from 'components/customer/Preview3D/helpers';
import {RoomPlannerFormValues} from 'components/customer/RoomPlanner/types';

type FormValues = {
    height: number;
    width: number;
    microwaveOpeningHeight: number;
    shelvesHeightReference: ShelvesTypeReference;
    shelvesHeightReference3Part: ShelvesTypeReference3Part;
    upperShelfOffset: number;
    lowerShelfOffset: number;
};

type ShelvesTypeReference = {
    upper_shelves: number;
    lower_shelves: number;
};

type ShelvesTypeReference3Part = ShelvesTypeReference & {
    middle_shelves: number;
};

export const getMultipartFormValues = (values: PreviewFormValues) => {
    const cabinetUpperPartitionHeight =
        Number(values.cabinet_upper_partition_height) > 1
            ? Number(values.cabinet_upper_partition_height) /
              Number(values.cabinet_height)
            : Number(values.cabinet_upper_partition_height);
    const cabinetLowerPartitionHeight =
        Number(values.cabinet_lower_partition_height) > 1
            ? Number(values.cabinet_lower_partition_height) /
              Number(values.cabinet_height)
            : Number(values.cabinet_lower_partition_height);

    const upperShelfOffset =
        cabinetUpperPartitionHeight * Number(values.cabinet_height);
    const lowerShelfOffset =
        cabinetLowerPartitionHeight * Number(values.cabinet_height);

    return {
        height: makeZeroIfUndefined(values.cabinet_height),
        width: makeZeroIfUndefined(values.cabinet_width),
        microwaveOpeningHeight: makeZeroIfUndefined(
            values.microwave_opening_height
        ),
        shelvesHeightReference: {
            upper_shelves:
                Number(values.cabinet_height) -
                Number(values.cabinet_partition_height),
            lower_shelves: Number(values.cabinet_partition_height),
        },
        shelvesHeightReference3Part: {
            upper_shelves: Number(values.cabinet_height) - upperShelfOffset,
            middle_shelves: upperShelfOffset - lowerShelfOffset,
            lower_shelves: lowerShelfOffset,
        },
        upperShelfOffset,
        lowerShelfOffset,
    };
};

export const generateMultiPart = (
    values: RoomPlannerFormValues,
    formValues?: FormValues
) => {
    const carcaseThickness = values.cabinet_carc_thickness;
    const exteriorThickness = values.cabinet_ext_thickness;
    const thicknessDiff = exteriorThickness - carcaseThickness;

    return {
        microwave: {
            bottomHeight: formValues.height - formValues.microwaveOpeningHeight,
            topHeight: formValues.microwaveOpeningHeight,
            topWidth: formValues.width - thicknessDiff,
            thicknessDiff,
        },
        pantry: {
            bottomHeight: formValues.shelvesHeightReference.lower_shelves,
            topHeight: formValues.shelvesHeightReference.upper_shelves,
            bottomBaseTopPosition:
                formValues.shelvesHeightReference.lower_shelves -
                carcaseThickness,
            upperSidePanelPosition:
                formValues.shelvesHeightReference.lower_shelves,
        },
        cornerPantry: {
            bottomHeight: formValues.shelvesHeightReference3Part.lower_shelves,
            topHeight: formValues.shelvesHeightReference3Part.upper_shelves,
            middleHeight: formValues.shelvesHeightReference3Part.middle_shelves,
            bottomBaseTopPosition:
                formValues.shelvesHeightReference3Part.lower_shelves -
                carcaseThickness,
            upperSidePanelPosition: formValues.upperShelfOffset,
            middleSidePanelPosition: formValues.lowerShelfOffset,
        },
    };
};
