import {makeZeroIfUndefined} from 'components/customer/Preview3D/helpers';
import {PreviewFormValues} from 'Preview3D/types';
import {RoomPlannerFormValues} from 'components/customer/RoomPlanner/types';

type FormValues = {
    height: number;
    depth: number;
    width: number;
    ovenOpeningHeight: number;
    topSectionHeight: number;
    totalDrawerHeight: number;
    microwaveOpeningHeight: number;
};

export const DEFAULT_UPPER_PAIR_DEPTH = 400;

export const getWallOvenFormValues = (values: PreviewFormValues) => {
    return {
        height: Number(values.cabinet_height),
        depth: Number(values.cabinet_depth),
        width: Number(values.cabinet_width),
        ovenOpeningHeight: Number(values.oven_opening_height) || 0,
        topSectionHeight:
            makeZeroIfUndefined(values.cabinet_height) -
            makeZeroIfUndefined(values.cabinet_total_drawer_height) -
            makeZeroIfUndefined(values.oven_opening_height) -
            makeZeroIfUndefined(values.microwave_opening_height),
        totalDrawerHeight:
            makeZeroIfUndefined(values.cabinet_total_drawer_height) +
            makeZeroIfUndefined(values.oven_opening_height),
        microwaveOpeningHeight: makeZeroIfUndefined(
            values.microwave_opening_height
        ),
    };
};

export const generateWallOvenValues = (
    values: RoomPlannerFormValues,
    formValues?: FormValues
) => {
    const exteriorThickness = values.cabinet_ext_thickness;

    return {
        baseHeight: formValues.totalDrawerHeight,
        hasMicrowave: formValues.microwaveOpeningHeight > 0,
        upperPair: {
            basePositionZ:
                Number(values.cabinet_height) - formValues.topSectionHeight,
            height: formValues.topSectionHeight,
            depth:
                formValues.depth >= DEFAULT_UPPER_PAIR_DEPTH
                    ? DEFAULT_UPPER_PAIR_DEPTH
                    : formValues.depth,
        },
        microwave: {
            topHeight:
                Number(values.cabinet_height) -
                formValues.topSectionHeight -
                exteriorThickness,
            bottomHeight:
                Number(values.cabinet_height) -
                formValues.topSectionHeight -
                formValues.microwaveOpeningHeight -
                exteriorThickness,
        },
    };
};
