import {
    ComplexShelfOperation,
    DynamicSegments,
    ShelfType,
    Drawer,
    AdvancedShelf,
    AdvancedUpperShelf,
    AdvancedLowerShelf,
    PreviewFormValues,
} from 'Preview3D/types';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import {
    makeZeroIfUndefined,
    convertShelfPositionToPercentage,
    getShelfStyle,
} from 'components/customer/Preview3D/helpers';
import {DEFAULT_UPPER_PAIR_DEPTH} from 'components/customer/Preview3D/values/useWallOvenDynamicValues';
import {RoomPlannerFormValues} from 'components/customer/RoomPlanner/types';

type FormValues = {
    height: number;
    width: number;
    depth: number;
    shelvesCount: number;
    shelvesVerticalCount: number;
    shelvesHorizontalCount: number;
    shelfType: ShelfType;
    drawers: Drawer[];
    drawerTop: number;
    drawerBottom: number;
    drawerGap: number;
    shelvesHeightReference: ShelvesTypeReference;
    partitionWidth: number;
    ladderFrames: number;
    toeKickHeight: number;
    shelfSetBack: number;
    simpleShelfType: number;
    rangehoodOpeningHeight: number;
    totalOvenAndDrawerHeight: number;
};

type Insert = {
    insert: number;
    depth?: number;
    label?: string;
};

type InsertWithWidth = Insert & {
    width: number;
};

type ShelvesTypeReference = {
    upper_shelves: number;
    lower_shelves: number;
};

export const getShelvesFormValues = (
    values: PreviewFormValues,
    shelfType: ShelfType
) => {
    return {
        height: makeZeroIfUndefined(values.cabinet_height),
        width: makeZeroIfUndefined(values.cabinet_width),
        depth: makeZeroIfUndefined(values.cabinet_depth),
        shelvesCount: Number(values.simple_shelves_amount),
        shelvesVerticalCount: Number(values.cabinet_vert_shelves),
        shelvesHorizontalCount: Number(values.cabinet_hori_shelves),
        shelfType: shelfType,
        drawers: values.drawers as unknown as Drawer[],
        drawerTop: makeZeroIfUndefined(values.cabinet_drawer_top),
        drawerBottom: makeZeroIfUndefined(values.cabinet_drawer_bottom),
        drawerGap: makeZeroIfUndefined(values.cabinet_drawer_gap),
        shelvesHeightReference: {
            upper_shelves:
                makeZeroIfUndefined(values.cabinet_height) -
                values.cabinet_partition_height,
            lower_shelves: values.cabinet_partition_height,
        },
        partitionWidth: values.cabinet_partition_width
            ? values.cabinet_partition_width < 1
                ? makeZeroIfUndefined(values.cabinet_width) *
                  values.cabinet_partition_width
                : makeZeroIfUndefined(values.cabinet_partition_width)
            : 0,
        ladderFrames: values.cabinet_ladder_frames
            ? makeZeroIfUndefined(values.cabinet_ladder_frames)
            : 0,
        toeKickHeight: values.cabinet_toekick
            ? makeZeroIfUndefined(values.cabinet_toekick)
            : 0,
        shelfSetBack: 5,
        simpleShelfType: Number(values.simple_shelf_type),
        rangehoodOpeningHeight: Number(values.rangehood_opening_height),
        totalOvenAndDrawerHeight:
            makeZeroIfUndefined(values.cabinet_total_drawer_height) +
            makeZeroIfUndefined(values.oven_opening_height) +
            makeZeroIfUndefined(values.microwave_opening_height),
    };
};

export const generateHorizontalShelves = (
    values: RoomPlannerFormValues,
    formValues: FormValues,
    variables: TemplateVariable
) => {
    const inserts: {
        insert: number;
        depth?: number;
        hasInsetDrawer?: boolean;
        label?: string;
    }[] = [];
    const advancedShelves = values.shelves;
    const shelvesCount = values.cabinet_simple_shelves
        ? formValues?.shelvesCount
        : advancedShelves?.length;
    const offset =
        formValues.drawers && formValues.drawers.length > 0
            ? formValues.drawers[0].drawer_face_height + formValues.drawerTop
            : 0;

    const carcaseThickness = values.cabinet_carc_thickness;
    const hasToeKick = variables.hasToeKick;
    const hasRangehoodVent = variables.hasRangehoodVent;
    const hasInsetDrawer = variables.hasInsetDrawer;

    const toeKickWithDrawers =
        hasToeKick && formValues.drawers && formValues.drawers.length > 0;
    let toeKickShelfStartPosition = 0;

    if (toeKickWithDrawers) {
        toeKickShelfStartPosition =
            formValues.drawers.reduce(
                (acc, obj) =>
                    acc + parseFloat(obj?.drawer_face_height.toString()),
                0
            ) +
            formValues.drawerTop +
            formValues.drawerBottom +
            formValues.drawerGap * (formValues.drawers.length - 1) +
            carcaseThickness;
    }

    const rangehoodSetback =
        hasRangehoodVent || Number(formValues.rangehoodOpeningHeight) > 0
            ? formValues.rangehoodOpeningHeight
            : 0;
    const insetDrawerSetback = hasInsetDrawer ? carcaseThickness : 0;

    let height =
        formValues?.height -
        (hasToeKick ? formValues.toeKickHeight : offset) -
        rangehoodSetback -
        toeKickShelfStartPosition;

    let wallOvenTotalHeight = 0;

    if (variables?.isWallOvenProduct) {
        height = formValues?.height - formValues.totalOvenAndDrawerHeight;
        wallOvenTotalHeight = formValues.totalOvenAndDrawerHeight;
    }

    const depth = values.cabinet_depth - carcaseThickness;
    const insertPositionYOffset =
        formValues.toeKickHeight +
        rangehoodSetback +
        toeKickShelfStartPosition -
        insetDrawerSetback +
        wallOvenTotalHeight;

    const insetDrawerOffset = hasInsetDrawer ? carcaseThickness : 0;
    const computedDepth =
        variables?.isWallOvenProduct &&
        values.cabinet_depth >= DEFAULT_UPPER_PAIR_DEPTH
            ? DEFAULT_UPPER_PAIR_DEPTH - carcaseThickness
            : depth;

    for (
        let index = 1 - (toeKickWithDrawers ? 1 : 0);
        index <= shelvesCount;
        index++
    ) {
        const isToeKickShelfWithDrawers = toeKickWithDrawers && index === 0;

        if (values.cabinet_simple_shelves || isToeKickShelfWithDrawers) {
            const style = formValues.simpleShelfType === 0 ? 'Fixed' : 'Adj';

            inserts.push({
                insert:
                    (height / (shelvesCount + 1)) * index +
                    insertPositionYOffset,
                depth:
                    computedDepth -
                    (isToeKickShelfWithDrawers ? 0 : formValues.shelfSetBack) -
                    insetDrawerOffset,
                hasInsetDrawer,
                label:
                    index === 0
                        ? 'Horizontal Partition'
                        : `Shelf ${index} - ${style}`,
            });
        } else {
            const selectedShelf = advancedShelves[index - 1];
            const style = getShelfStyle(selectedShelf?.shelf_style);

            if (selectedShelf && Number(selectedShelf.shelf_type) !== 9)
                inserts.push({
                    insert:
                        height *
                            convertShelfPositionToPercentage(
                                selectedShelf.shelf_position,
                                height
                            ) +
                        insertPositionYOffset,
                    depth:
                        computedDepth -
                        selectedShelf.shelf_offset -
                        insetDrawerOffset,
                    hasInsetDrawer,
                    label:
                        index === 0
                            ? 'Horizontal Partition'
                            : `Shelf ${index} - ${style}`,
                });
        }
    }

    return inserts;
};

export const generateComplexHorizontalShelves = (
    values: RoomPlannerFormValues,
    formValues: FormValues
) => {
    const shelvesCount = formValues?.shelvesHorizontalCount;
    const shelvesCountVertical = formValues?.shelvesVerticalCount;
    const complexShelfOperation: ComplexShelfOperation[] = [];
    const exteriorThickness = values.cabinet_ext_thickness;

    for (let index = 1; index <= shelvesCount; index++) {
        if (formValues?.shelfType == 'COMPLEX') {
            const segments: DynamicSegments[] = [];
            const insert = (formValues?.height / (shelvesCount + 1)) * index;

            for (let index2 = 1; index2 <= shelvesCountVertical; index2++) {
                const calculation =
                    (formValues?.width / (shelvesCountVertical + 1)) * index2;

                segments.push({
                    first: {
                        StartCoor: {
                            x: calculation - exteriorThickness,
                        },
                        EndCoor: {
                            x: calculation - exteriorThickness,
                        },
                    },
                    second: {
                        EndCoor: {
                            x: calculation,
                        },
                        StartCoor: {
                            x: calculation - exteriorThickness,
                        },
                    },
                    third: {
                        EndCoor: {
                            x: calculation,
                        },
                        StartCoor: {
                            x: calculation,
                        },
                    },
                    fourth: {
                        StartCoor: {
                            x: calculation,
                        },
                    },
                });
            }

            complexShelfOperation.push({
                insert,
                openBaseCutSegments: segments,
            });
        }
    }

    return {
        shelves: complexShelfOperation,
        depth: formValues.depth - exteriorThickness,
        width: formValues.width - exteriorThickness,
    };
};

export const generateComplexVerticalShelves = (
    values: RoomPlannerFormValues,
    formValues: FormValues
) => {
    const shelvesCount = formValues?.shelvesVerticalCount;
    const shelvesCountHorizontal = formValues?.shelvesHorizontalCount;
    const complexShelfOperation: ComplexShelfOperation[] = [];
    const exteriorThickness = values.cabinet_ext_thickness;

    for (let index = 1; index <= shelvesCount; index++) {
        if (formValues?.shelfType == 'COMPLEX') {
            const segments: DynamicSegments[] = [];
            const insert = (formValues?.width / (shelvesCount + 1)) * index;

            for (let index2 = 1; index2 <= shelvesCountHorizontal; index2++) {
                const calculation =
                    (formValues?.height / (shelvesCountHorizontal + 1)) *
                    index2;

                segments.push({
                    first: {
                        EndCoor: {
                            x: calculation,
                        },
                    },
                    second: {
                        EndCoor: {
                            x: calculation,
                        },
                        StartCoor: {
                            x: calculation,
                        },
                    },
                    third: {
                        EndCoor: {
                            x: calculation - exteriorThickness,
                        },
                        StartCoor: {
                            x: calculation,
                        },
                    },
                    fourth: {
                        EndCoor: {
                            x: calculation - exteriorThickness,
                        },
                        StartCoor: {
                            x: calculation - exteriorThickness,
                        },
                    },
                });
            }

            complexShelfOperation.push({
                insert,
                openBaseCutSegments: segments,
            });
        }
    }

    return {
        shelves: complexShelfOperation,
        depth: formValues.depth - exteriorThickness,
        height: formValues.height - exteriorThickness,
    };
};

export const generateUpperAndLowerShelves = (
    values: RoomPlannerFormValues,
    formValues: FormValues
) => {
    const upperShelvesCount = values?.upper_shelves
        ? (values?.upper_shelves as unknown as Array<number>)?.length
        : 0;
    const lowerShelvesCount = values?.lower_shelves
        ? (values?.lower_shelves as unknown as Array<number>)?.length
        : 0;
    const carcaseThickness = values.cabinet_carc_thickness;

    const upperShelfInserts: Insert[] = [];
    const lowerShelfInserts: Insert[] = [];
    const upperShelves =
        values.upper_shelves as unknown as AdvancedUpperShelf[];
    const lowerShelves =
        values.lower_shelves as unknown as AdvancedLowerShelf[];
    const shelvesHeightReference = formValues.shelvesHeightReference;

    const upperShelfOffset = shelvesHeightReference.lower_shelves;
    const height = formValues?.height - upperShelfOffset;
    const depth = values.cabinet_depth - carcaseThickness;
    const partitionPosition = shelvesHeightReference.lower_shelves;

    for (let index = 1; index <= upperShelvesCount; index++) {
        const selectedShelf = upperShelves[index - 1];
        const style = selectedShelf?.upper_shelf_style === 0 ? 'Fixed' : 'Adj';

        if (selectedShelf && Number(selectedShelf.upper_shelf_type) !== 9)
            upperShelfInserts.push({
                insert:
                    height *
                        convertShelfPositionToPercentage(
                            selectedShelf.upper_shelf_position,
                            height
                        ) +
                    upperShelfOffset,
                depth: depth - selectedShelf.upper_shelf_offset,
                label: `Upper Shelf ${index} - ${style}`,
            });
    }

    const height2 = upperShelfOffset;

    for (let index = 1; index <= lowerShelvesCount; index++) {
        const selectedShelf = lowerShelves[index - 1];
        const style = selectedShelf?.lower_shelf_style === 0 ? 'Fixed' : 'Adj';

        if (selectedShelf && Number(selectedShelf.lower_shelf_type) !== 9)
            lowerShelfInserts.push({
                insert:
                    height2 *
                    convertShelfPositionToPercentage(
                        selectedShelf.lower_shelf_position,
                        height2
                    ),
                depth: depth - selectedShelf.lower_shelf_offset,
                label: `Lower Shelf ${index} - ${style}`,
            });
    }

    return {upperShelfInserts, lowerShelfInserts, partitionPosition};
};

export const generateUpperAndLowerLeftRightShelves = (
    values: RoomPlannerFormValues,
    formValues: FormValues,
    variables: TemplateVariable
) => {
    const isBroom2Part = Boolean(variables.isBroom2Part);

    const upperShelvesCount = values?.upper_shelves
        ? (values?.upper_shelves as unknown as Array<number>)?.length
        : 0;
    const lowerLeftShelvesCount = values?.lower_shelves
        ? (values?.lower_shelves as unknown as Array<number>)?.length
        : 0;
    const lowerRightShelvesCount = values?.shelves
        ? (values?.shelves as unknown as Array<number>)?.length
        : 0;

    const upperShelfInserts: Insert[] = [];
    const lowerLeftShelfInserts: InsertWithWidth[] = [];
    const lowerRightShelfInserts: InsertWithWidth[] = [];
    const carcaseThickness = values.cabinet_carc_thickness;

    const upperShelves =
        values.upper_shelves as unknown as AdvancedUpperShelf[];
    const lowerLeftShelves =
        values.lower_shelves as unknown as AdvancedLowerShelf[];
    const lowerRightShelves = values.shelves as unknown as AdvancedShelf[];

    const shelvesHeightReference = formValues.shelvesHeightReference;

    const upperShelfOffset = shelvesHeightReference.lower_shelves;
    const height = formValues?.height - upperShelfOffset;
    const depth = values.cabinet_depth - carcaseThickness;
    const partitionPosition = shelvesHeightReference.lower_shelves;
    const verticalPartitionPosition =
        formValues.partitionWidth + carcaseThickness;
    const verticalPartitionHeight =
        upperShelfOffset -
        carcaseThickness -
        (isBroom2Part ? carcaseThickness : 0);

    for (let index = 1; index <= upperShelvesCount; index++) {
        const selectedShelf = upperShelves[index - 1];
        const style = selectedShelf?.upper_shelf_style === 0 ? 'Fixed' : 'Adj';

        if (selectedShelf && Number(selectedShelf.upper_shelf_type) !== 9)
            upperShelfInserts.push({
                insert:
                    height *
                        convertShelfPositionToPercentage(
                            selectedShelf.upper_shelf_position,
                            height
                        ) +
                    upperShelfOffset,
                depth: depth - selectedShelf.upper_shelf_offset,
                label: `Upper Shelf ${index} - ${style}`,
            });
    }

    const height2 = upperShelfOffset;

    for (let index = 1; index <= lowerLeftShelvesCount; index++) {
        const selectedShelf = lowerLeftShelves[index - 1];
        const style = selectedShelf?.lower_shelf_style === 0 ? 'Fixed' : 'Adj';

        if (selectedShelf && Number(selectedShelf.lower_shelf_type) !== 9)
            lowerLeftShelfInserts.push({
                insert:
                    height2 *
                    convertShelfPositionToPercentage(
                        selectedShelf.lower_shelf_position,
                        height2
                    ),
                depth: depth - selectedShelf.lower_shelf_offset,
                width: formValues.partitionWidth - carcaseThickness,
                label: `BL ${index} - ${style}`,
            });
    }

    for (let index = 1; index <= lowerRightShelvesCount; index++) {
        const selectedShelf = lowerRightShelves[index - 1];
        const style = getShelfStyle(selectedShelf?.shelf_style);

        if (selectedShelf && Number(selectedShelf.shelf_type) !== 9)
            lowerRightShelfInserts.push({
                insert:
                    height2 *
                    convertShelfPositionToPercentage(
                        selectedShelf.shelf_position,
                        height2
                    ),
                depth: depth - selectedShelf.shelf_offset,
                width:
                    formValues.width -
                    formValues.partitionWidth -
                    carcaseThickness,
                label: `BR ${index} - ${style}`,
            });
    }

    return {
        upperShelfInserts,
        lowerLeftShelfInserts,
        lowerRightShelfInserts,
        partitionPosition,
        verticalPartitionPosition,
        verticalPartitionHeight,
    };
};
