import {useState} from 'react';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {SizeDefaultsFields} from 'components/customer/Room/SizeDefaultField';

type Position = {
    [key in SizeDefaultsFields]?: {
        arrowWidth?: string;
        lineWidth?: number;
        lineHeight?: number;
        lineX?: number;
        lineY?: number;
        arrowX?: number;
        arrowY?: number;
        topLineY?: number;
        bottomLineY?: number;
        topArrowY?: number;
        bottomArrowY?: number;
        arrowX1?: number;
        arrowX2?: number;
        topArrowY1?: number;
        topArrowY2?: number;
        bottomArrowY1?: number;
        bottomArrowY2?: number;
        leftArrowX?: number;
        rightArrowX?: number;
    };
};

export const useGapSizeDefaultPreview = () => {
    const [position, setPosition] = useState<Position>({});
    const [fade, setFade] = useState<SizeDefaultsFields>();
    const isSmallDevice = isDeviceSmall();

    const focusAction = (
        imageId: SizeDefaultsFields,
        inputId?: SizeDefaultsFields
    ) => {
        const image = document.getElementById(`${imageId}_image_id`);
        const container = document.getElementById(`${imageId}_container_id`);

        if (container && image) {
            const containerDimension = container.getBoundingClientRect();
            const dimension = image.getBoundingClientRect();

            const width = dimension.width;
            const height = dimension.height;
            const xOffset = dimension.left - containerDimension.left;
            const arrowWidth = isSmallDevice ? '20px' : '30px';

            let position;

            switch (imageId) {
                case SizeDefaultsFields.DOOR_GAP:
                    setPosition({
                        [imageId]: {
                            arrowWidth,
                            lineHeight: height * 0.67,
                            lineX: xOffset + width / 2,
                            lineY: height * 0.17,
                            arrowX:
                                xOffset + width / 2 - (isSmallDevice ? 9 : 14),
                            topArrowY: isSmallDevice ? -6 : 0,
                            bottomArrowY: height * 0.85,
                        },
                    });

                    break;

                case SizeDefaultsFields.DRAWER_GAP:
                    setPosition({
                        [imageId]: {
                            arrowWidth,
                            lineWidth: width * 0.5,
                            lineX: xOffset + width * 0.05,
                            topLineY: height * 0.34,
                            bottomLineY: height * 0.56,
                            arrowX1:
                                xOffset +
                                width * 0.05 -
                                (isSmallDevice ? 20 : 30),
                            arrowX2:
                                xOffset +
                                width * 0.48 +
                                (isSmallDevice ? 9 : 19),
                            topArrowY1:
                                height * 0.29 - (isSmallDevice ? 7.5 : 15),
                            topArrowY2:
                                height * 0.4 - (isSmallDevice ? 7.5 : 15),
                            bottomArrowY1:
                                height * 0.5 - (isSmallDevice ? 7.5 : 15),
                            bottomArrowY2:
                                height * 0.63 - (isSmallDevice ? 7.5 : 15),
                        },
                    });

                    break;

                case SizeDefaultsFields.GAP:
                    if (inputId && inputId == SizeDefaultsFields.LEFT_GAP) {
                        position = {
                            [inputId]: {
                                lineHeight: height * 0.69,
                                lineX: xOffset + width * 0.09,
                                lineY: height * 0.17,
                                arrowX: xOffset + width * 0.09 - 10,
                                topArrowY: 0,
                                bottomArrowY: height * 0.87,
                            },
                        };
                    } else {
                        position = {
                            [inputId]: {
                                lineHeight: height * 0.69,
                                lineX: xOffset + width - width * 0.09 - 1,
                                lineY: height * 0.17,
                                arrowX: xOffset + width - width * 0.09 - 10,
                                topArrowY: 0,
                                bottomArrowY: height * 0.87,
                            },
                        };
                    }

                    setPosition(position);
                    break;

                case SizeDefaultsFields.UPPER_MARGIN:
                    if (
                        inputId &&
                        inputId == SizeDefaultsFields.UPPER_TOP_MARGIN
                    ) {
                        position = {
                            [inputId]: {
                                arrowWidth,
                                lineWidth: width * 0.86,
                                lineX: xOffset + width * 0.08,
                                lineY: height * 0.04,
                                arrowY:
                                    height * 0.04 - (isSmallDevice ? 9 : 15),
                                leftArrowX:
                                    xOffset +
                                    width * 0.08 -
                                    (isSmallDevice ? 21.5 : 35),
                                rightArrowX: xOffset + width - width * 0.08 + 5,
                            },
                        };
                    } else {
                        position = {
                            [inputId]: {
                                arrowWidth,
                                lineWidth: width * 0.94,
                                lineX: xOffset + width * 0.03,
                                lineY: height * 0.91,
                                arrowY:
                                    height * 0.89 - (isSmallDevice ? 8 : 12),
                                leftArrowX:
                                    xOffset +
                                    width * 0.03 -
                                    (isSmallDevice ? 23.5 : 35),
                                rightArrowX: xOffset + width - width * 0.03 + 3,
                            },
                        };
                    }

                    setPosition(position);
                    break;

                case SizeDefaultsFields.BASE_MARGIN:
                    if (
                        inputId &&
                        inputId == SizeDefaultsFields.BASE_TOP_MARGIN
                    ) {
                        position = {
                            [inputId]: {
                                arrowWidth,
                                lineWidth: width * 0.9,
                                lineX: xOffset + width * 0.05,
                                lineY: height * 0.17,
                                arrowY:
                                    height * 0.17 - (isSmallDevice ? 8.5 : 15),
                                leftArrowX:
                                    xOffset +
                                    width * 0.054 -
                                    (isSmallDevice ? 21.5 : 35),
                                rightArrowX:
                                    xOffset +
                                    width -
                                    width * 0.054 +
                                    (isSmallDevice ? 2 : 5),
                            },
                        };
                    } else {
                        position = {
                            [inputId]: {
                                arrowWidth,
                                lineWidth: width * 0.86,
                                lineX: xOffset + width * 0.08,
                                lineY: height * (isSmallDevice ? 0.83 : 0.84),
                                arrowY:
                                    height * 0.84 - (isSmallDevice ? 10 : 14),
                                leftArrowX:
                                    xOffset +
                                    width * 0.09 -
                                    (isSmallDevice ? 22.5 : 35),
                                rightArrowX:
                                    xOffset +
                                    width -
                                    width * 0.09 +
                                    (isSmallDevice ? 5 : 7),
                            },
                        };
                    }

                    setPosition(position);
                    break;
            }

            window.setTimeout(() => {
                setFade(inputId ? inputId : imageId);
            }, 50);
        }
    };

    return {
        fade,
        position,
        focusAction,
    };
};
