import React from 'react';
import {
    FieldType,
    SizeDefaultField,
} from 'components/customer/Room/SizeDefaultField';
import {WizardComponent} from 'shared/components/Wizard/entity/WizardComponent';

type Field = {
    name: string;
    moreInfo: number;
    type: FieldType;
    label: string;
    doorStyleField?: string;
    style: string;
};

const fields: Field[] = [
    {
        name: 'doorBorderWidthTop',
        moreInfo: 30,
        type: FieldType.NUMBER,
        label: 'Border Width Top',
        doorStyleField: 'default_border_width_top',
        style: 'doorBorders',
    },
    {
        name: 'doorBorderWidthBottom',
        moreInfo: 31,
        type: FieldType.NUMBER,
        label: 'Border Width Bottom',
        doorStyleField: 'default_border_width_bottom',
        style: 'doorBorders',
    },
    {
        name: 'doorBorderWidthLeft',
        moreInfo: 32,
        type: FieldType.NUMBER,
        label: 'Border Width Left',
        doorStyleField: 'default_border_width_left',
        style: 'doorBorders',
    },
    {
        name: 'doorBorderWidthRight',
        moreInfo: 33,
        type: FieldType.NUMBER,
        label: 'Border Width Right',
        doorStyleField: 'default_border_width_right',
        style: 'doorBorders',
    },
    {
        name: 'doorAdvancedRailHeight',
        moreInfo: 38,
        type: FieldType.NUMBER,
        label: 'Rail Height',
        doorStyleField: 'default_rails_horizontal_height',
        style: 'doorRails',
    },
    {
        name: 'doorAdvancedRailWidth',
        moreInfo: 39,
        type: FieldType.NUMBER,
        label: 'Rail Width',
        doorStyleField: 'default_rails_vertical_width',
        style: 'doorRails',
    },
    {
        name: 'doorAdvancedBarWidth',
        moreInfo: 40,
        type: FieldType.NUMBER,
        label: 'Bar Width',
        style: 'doorBar',
    },
    {
        name: 'doorAdvancedEdgeTop',
        moreInfo: 34,
        type: FieldType.DROPDOWN,
        label: 'Edge Top',
        style: 'doorEdge',
    },
    {
        name: 'doorAdvancedEdgeBottom',
        moreInfo: 35,
        type: FieldType.DROPDOWN,
        label: 'Edge Bottom',
        style: 'doorEdge',
    },
    {
        name: 'doorAdvancedEdgeLeft',
        moreInfo: 36,
        type: FieldType.DROPDOWN,
        label: 'Edge Left',
        style: 'doorEdge',
    },
    {
        name: 'doorAdvancedEdgeRight',
        moreInfo: 37,
        type: FieldType.DROPDOWN,
        label: 'Edge Right',
        style: 'doorEdge',
    },
];

const edgeDropDown = [
    {
        id: 0,
        value: 0,
        name: 'Square',
    },
    {
        id: 2,
        value: 0,
        name: 'Profile',
    },
];

export const AdvancedDoors = ({}: WizardComponent) => {
    return (
        <>
            {fields.map((field) => {
                return (
                    <SizeDefaultField
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        moreInfo={field.moreInfo}
                        type={field.type}
                        options={edgeDropDown}
                    />
                );
            })}
        </>
    );
};
