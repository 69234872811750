import {mapDoor} from 'components/customer/Materials/helper/doorHelper';
import {mapMaterialEdge} from 'components/customer/Materials/helper/edgeHelper';
import {mapMaterial} from 'components/customer/Materials/helper/materialHelper';
import {
    getMaterialRestrictionDetails,
    MaterialFields,
} from 'components/customer/Product/helpers/getMaterialRestrictionDetails';
import {PartialRoom} from 'shared/types/PartialRoom';

export const getMaterialRestrictions = (
    room: PartialRoom,
    materialFields: MaterialFields
) => {
    const materials = {
        exterior: mapMaterial(room.extMaterial),
        edgeExterior: mapMaterialEdge(room.extEdge),
        carcase: mapMaterial(room.carcMaterial),
        edgeCarcase: mapMaterialEdge(room.carcEdge),
        door: mapDoor(room.door),
    };

    return getMaterialRestrictionDetails(materials, materialFields);
};
