import React from 'react';
import {Col, Form} from 'react-bootstrap';
import {FormGroup} from 'shared/components/Forms/FormGroup';

interface NameAndDescriptionProps {
    values: {
        name: string;
        description: string;
    };
    handleChange: (event: React.ChangeEvent) => void;
    touched: {
        name?: boolean;
        description?: boolean;
    };
    errors: {
        name?: string;
        description?: string;
    };
}

export const NameAndDescription = ({
    values,
    handleChange,
    touched,
    errors,
}: NameAndDescriptionProps) => {
    return (
        <div className="jumbotron">
            <FormGroup controlId="name_">
                <Form.Label column md={{offset: 1, span: 2}}>
                    Name: <span>*</span>
                </Form.Label>
                <Col md="7">
                    <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={touched.name && errors.name ? true : false}
                        style={{
                            height: 'auto',
                            padding: '5px 10px',
                            lineHeight: '1px',
                            fontSize: '.85em',
                        }}
                    />
                </Col>
            </FormGroup>
            <FormGroup controlId="description_">
                <Form.Label column md={{offset: 1, span: 2}}>
                    Description:{' '}
                </Form.Label>
                <Col md="7">
                    <Form.Control
                        as="textarea"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        isInvalid={
                            touched.description && errors.description
                                ? true
                                : false
                        }
                        style={{
                            padding: '5px',
                            fontSize: '.85em',
                        }}
                    />
                </Col>
            </FormGroup>
        </div>
    );
};
