import {PreviewFormValues} from 'Preview3D/types';
import {RoomPlannerFormValues} from 'components/customer/RoomPlanner/types';

const SIDE_PANEL_OFFSET_Z = 1;

export const getDefaultFormValues = (values: PreviewFormValues) => {
    return {
        depth: Number(values.cabinet_depth),
        width: Number(values.cabinet_width),
        cabinetTop: Number(values.cabinet_top),
        height: Number(values.cabinet_height),
    };
};

export const getDefaultValues = (
    values: RoomPlannerFormValues,
    formValues: ReturnType<typeof getDefaultFormValues>
) => {
    const carcaseThickness = values.cabinet_carc_thickness;
    const exteriorThickness = values.cabinet_ext_thickness;

    return {
        baseBottomDepth: formValues.depth - carcaseThickness,
        baseBottomWidth: formValues.width - carcaseThickness,
        baseBackDepth: formValues.depth - carcaseThickness,
        baseBackWidth: formValues.width - carcaseThickness,
        baseFrontWidth: formValues.width + carcaseThickness,
        baseBottomDepthExt: formValues.depth - exteriorThickness,
        baseBackWidthExt: formValues.width - exteriorThickness,
        // To fix the misalignment of base side panels
        baseSideHeight: formValues.height + SIDE_PANEL_OFFSET_Z,
        sidePanelOffsetZ: SIDE_PANEL_OFFSET_Z,
        exteriorThickness,
        carcaseThickness,
        isTimberRailOnEdge: formValues.cabinetTop == 175,
    };
};
