import {Product} from 'components/customer/Product/entity/Product';

export const mapParamsForPriceRequest = (product: Partial<Product>) => {
    const faceHeight =
        Array.isArray(product.drawer_face_height) &&
        product.drawer_face_height.length == 0
            ? [product.height]
            : product.drawer_face_height;

    // Compile initial params
    const paramsQuery = {
        jobId: parseInt(product.job_id) + 10000,
        roomId: product.room_id,
        // Product List fields
        job_cabinet_id: product.job_cabinet_id || 0,
        cabinet_type: product.cabinet_type,
        cabinet_quantity: product.cabinet_quantity,
        cabinet_height: product.height, // Labeled as "Length"
        cabinet_width: product.width,
        cabinet_ext_colour: product.cabinet_ext_colour,
        cabinet_ext_edge_colour: product.cabinet_ext_edge_colour,
        cabinet_carc_colour: product.cabinet_ext_colour,
        cabinet_carc_edge_colour: product.cabinet_ext_edge_colour,
        cabinet_comment: product.cabinet_comment,
        cabinet_include_hardware: product.cabinet_include_hardware,
        supply_hardware: product.cabinet_include_hardware == 1 ? 1 : 0,
        // -- Apply special params for some cabinet types
        cabinet_panel_length: product.height, // Sets the height of a component product
        cabinet_panel_width: product.width, // Sets the width of a component product
        cabinet_applied_panel_depth: product.width, // Used by an applied panel. Set to 'appliedPanelDepth'
        // Cabinet Edge Specs fields
        cabinet_edge_l1: product.cabinet_edge_l1,
        cabinet_edge_l2: product.cabinet_edge_l2,
        cabinet_edge_w1: product.cabinet_edge_w1,
        cabinet_edge_w2: product.cabinet_edge_w2,
        cabinet_edge_inner: product.cabinet_edge_inner,
        // Door Edge Specs fields
        panel_edge_top: product.panel_edge_top,
        panel_edge_left: product.panel_edge_left,
        panel_edge_right: product.panel_edge_right,
        panel_edge_bottom: product.panel_edge_bottom,
        // Door Specs
        cabinet_door: product.cabinet_door,
        door_hang_type: product.door_hang_type,
        door_hang: product.door_hang,
        hinge_style: product.hinge_style,
        cabinet_width_door_1: product.cabinet_width_door_1
            ? product.cabinet_width_door_1
            : product.width,
        cabinet_width_door_2: product.cabinet_width_door_2
            ? product.cabinet_width_door_2
            : product.width1,
        // Door Drillings
        hinge: product.drillings
            ? product.drillings.map((drilling) => ({
                  offset_y: drilling.drilling_offset_y,
              }))
            : [],
        // Additional Dimensions
        cabinet_extend: product.cabinet_extend,
        cabinet_toekick: product.cabinet_toekick,
        // Advanced material params
        // -- Border Widths
        border_width_top: product.border_width_top,
        border_width_bottom: product.border_width_bottom,
        border_width_left: product.border_width_left,
        border_width_right: product.border_width_right,
        // -- Mid Rails
        hori_amount: product.hori_amount,
        hori_height: product.hori_height,
        rail_hori: product.hori_mid_rail_positions,
        vert_amount: product.vert_amount,
        vert_width: product.vert_width,
        rail_vert: product.vert_mid_rail_positions,
        // -- Glass Sub Panels (enabled glass positions are set below)
        no_vert_bars: product.no_vert_bars,
        no_hori_bars: product.no_hori_bars,
        bar_width: product.bar_width,
        hor_grain_carc: product.ext_hor_grain,
        hor_grain_ext: product.ext_hor_grain,
        double_sided_carc: product.double_sided,
        double_sided_ext: product.double_sided,
        custom_colour_ext: product.ext_custom_colour
            ? `${product.ext_custom_colour}`
            : '',
        custom_colour_carc: product.ext_custom_colour
            ? `${product.ext_custom_colour}`
            : '',
        drawer_amount: faceHeight && faceHeight.length,
        drawer_panel_edges: product?.drawer_panel_edges,
        drawer_face_type: product.drawer_face_type,
        drawer_face_height: faceHeight ? faceHeight : [],
        cabinet_drawer_gap: product.cabinet_drawer_gap,
        inQfpSummary: 1,
        sub_panel_1: product.glassSubPanels && product.glassSubPanels[0][0],
        sub_panel_enabled_1:
            product &&
            product.glassSubPanelEnabledPositions &&
            product.glassSubPanelEnabledPositions.sub_panel_enabled_1,
        sub_panel_2: product.glassSubPanels && product.glassSubPanels[0][1],
        sub_panel_enabled_2:
            product &&
            product.glassSubPanelEnabledPositions &&
            product.glassSubPanelEnabledPositions.sub_panel_enabled_2,
        sub_panel_3: product.glassSubPanels && product.glassSubPanels[0][2],
        sub_panel_enabled_3:
            product &&
            product.glassSubPanelEnabledPositions &&
            product.glassSubPanelEnabledPositions.sub_panel_enabled_3,
        sub_panel_4: product.glassSubPanels && product.glassSubPanels[1][0],
        sub_panel_enabled_4:
            product &&
            product.glassSubPanelEnabledPositions &&
            product.glassSubPanelEnabledPositions.sub_panel_enabled_4,
        sub_panel_5: product.glassSubPanels && product.glassSubPanels[1][1],
        sub_panel_enabled_5:
            product &&
            product.glassSubPanelEnabledPositions &&
            product.glassSubPanelEnabledPositions.sub_panel_enabled_5,
        sub_panel_6: product.glassSubPanels && product.glassSubPanels[1][2],
        sub_panel_enabled_6:
            product &&
            product.glassSubPanelEnabledPositions &&
            product.glassSubPanelEnabledPositions.sub_panel_enabled_6,
        sub_panel_7: product.glassSubPanels && product.glassSubPanels[2][0],
        sub_panel_enabled_7:
            product &&
            product.glassSubPanelEnabledPositions &&
            product.glassSubPanelEnabledPositions.sub_panel_enabled_7,
        sub_panel_8: product.glassSubPanels && product.glassSubPanels[2][1],
        sub_panel_enabled_8:
            product &&
            product.glassSubPanelEnabledPositions &&
            product.glassSubPanelEnabledPositions.sub_panel_enabled_8,
        sub_panel_9: product.glassSubPanels && product.glassSubPanels[2][2],
        sub_panel_enabled_9:
            product &&
            product.glassSubPanelEnabledPositions &&
            product.glassSubPanelEnabledPositions.sub_panel_enabled_9,
        panel_edge_join:
            product.panel_edge_join > -1 ? product.panel_edge_join : 0,
        rail_hori: [],
        rail_vert: [],
    };

    return paramsQuery;
};
