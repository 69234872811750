import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {MoreInfoData, useConfirmationDialog} from 'shared';
import {useFormikContext} from 'formik';
import {shallowEqual} from 'react-redux';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {WizardComponent} from 'shared/components/Wizard/entity/WizardComponent';
import {useSupplyDefaults} from 'components/customer/Room/helpers/useSupplyDefaults';
import {useHardwareSelection} from 'components/customer/Room/helpers/useHardwareSelection';
import {
    cabinetTopSet,
    drawerStyleSet,
    hingeStyleSet,
    selectCabinetTop,
    selectDrawerStyle,
    selectHingeStyle,
} from 'components/customer/Room/store/roomSlice';
import {RoomMessage} from 'components/customer/Room/SupplyDefaults';
import {HardwareSelectionField} from './HardwareSelectionField';
import {RoomFields} from 'components/customer/Room/RoomComponents';

interface HardwareSelectionProps extends WizardComponent {
    settings?: boolean;
    isMiniEditor?: boolean;
}

export const HardwareSelection = ({
    settings = false,
    isMiniEditor = false,
}: HardwareSelectionProps) => {
    const dispatch = useAppDispatch();
    const {values, setFieldValue} = useFormikContext<RoomFields>();
    const {
        userProfile,
        hingeStyles,
        loadingHingeStyles,
        cabinetTops: cabinetTopsUnfiltered,
        loadingCabinetTops,
        drawerSystems: drawerSystemsUnfiltered,
        loadingDrawerSystems,
    } = useHardwareSelection();
    const {supplyMethods, hardwareInclusions} = useSupplyDefaults();
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();
    const [message, setMessage] = useState<RoomMessage>({});

    const selectedHinge = useAppSelector(selectHingeStyle, shallowEqual);
    const selectedDrawer = useAppSelector(selectDrawerStyle, shallowEqual);
    const selectedCabinetTop = useAppSelector(selectCabinetTop, shallowEqual);

    const cabinetTops = useMemo(() => {
        if (cabinetTopsUnfiltered) {
            return cabinetTopsUnfiltered.filter(
                (cabinetTop) => cabinetTop.visibleInRoom == 1
            );
        }
    }, [cabinetTopsUnfiltered]);

    const drawerSystems = useMemo(() => {
        if (drawerSystemsUnfiltered) {
            return drawerSystemsUnfiltered.filter(
                (drawerSystem) => !drawerSystem.hiddenFromRoom
            );
        }
    }, [drawerSystemsUnfiltered]);

    const mappedHingeStyles = useMemo(() => {
        if (hingeStyles) {
            if (values.hardwareInclusions == 1) {
                return hingeStyles
                    .filter(
                        ({name}) =>
                            !userProfile.isAssemblyOnly ||
                            !name?.toLowerCase().includes('drill only')
                    )
                    .map((hingeStyle) => {
                        if (
                            hingeStyle.name
                                .toLowerCase()
                                .indexOf('drill only') >= 0
                        ) {
                            return {
                                ...hingeStyle,
                                disabled: true,
                            };
                        }

                        return hingeStyle;
                    });
            }

            return hingeStyles;
        }

        return [];
    }, [hingeStyles, values.hardwareInclusions, userProfile]);

    const mappedDrawerSystems = useMemo(() => {
        if (drawerSystems) {
            if (values.hardwareInclusions == 1) {
                return drawerSystems
                    .filter(
                        ({drillOnly}) =>
                            !userProfile.isAssemblyOnly || !drillOnly
                    )
                    .map((drawerSystem) => {
                        if (drawerSystem.drillOnly) {
                            return {
                                ...drawerSystem,
                                disabled: true,
                            };
                        }

                        return drawerSystem;
                    });
            }
            return drawerSystems;
        }

        return [];
    }, [drawerSystems, values.hardwareInclusions, userProfile]);

    const showDrillOnlyPrompt = useCallback(
        (hingeStyle: string, type: string) => {
            if (supplyMethods.length && hardwareInclusions.length) {
                const noOptionSupply =
                    supplyMethods.length &&
                    supplyMethods.find((method) => !method.value);
                const noOptionHardware =
                    hardwareInclusions.length &&
                    hardwareInclusions.find((method) => !method.value);

                const message: RoomMessage = {
                    title: hingeStyle,
                    text: `The ${type} style selected will only permit products to be ordered as <strong>${noOptionHardware.name}</strong> and <strong>${noOptionSupply.name}</strong>`,
                };

                setMessage(message);
            }
        },
        [supplyMethods, hardwareInclusions]
    );

    const setHingeStyle = useCallback(
        (name: string, value: number) => {
            const hingeStyle = hingeStyles.find((hinge) => hinge.id == value);

            if (hingeStyle) {
                if (hingeStyle.name.toLowerCase().indexOf('drill only') >= 0) {
                    showDrillOnlyPrompt(hingeStyle.name, 'drill only hinge');
                }
                dispatch(hingeStyleSet(hingeStyle));
            }
            void setFieldValue(name, value);
        },
        [hingeStyles, showDrillOnlyPrompt]
    );

    const setDrawerSystem = useCallback(
        (name: string, value: number) => {
            const drawerSystem = drawerSystems.find(
                (drawer) => drawer.id == value
            );

            if (drawerSystem) {
                if (drawerSystem.drillOnly) {
                    showDrillOnlyPrompt(drawerSystem.name, 'drawer');
                }
                dispatch(drawerStyleSet(drawerSystem));
            }
            void setFieldValue(name, value);
        },
        [drawerSystems, showDrillOnlyPrompt]
    );

    const setCabinetTop = useCallback(
        (name: string, value: number) => {
            if (!userProfile.isAddProductAvailable) return;

            const cabinetTop = cabinetTops.find(
                (cabinet) => cabinet.id == value
            );

            if (cabinetTop) {
                dispatch(cabinetTopSet(cabinetTop));
            }
            void setFieldValue(name, value);
        },
        [cabinetTops, userProfile]
    );

    useEffect(() => {
        if (message.title) {
            showDialog({
                title: message.title,
                message: message.text,
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        name: 'Ok',
                        show: true,
                        action: () => {
                            setMessage({});
                            hideDialog();
                        },
                    },
                ],
            });
        }
    }, [message]);

    return (
        <div className="relativePos">
            {dialog}
            <HardwareSelectionField
                setting={settings}
                label="Select your default hinge style"
                moreInfo={MoreInfoData.hingeStyle}
                name="hingeStyle"
                value={values.hingeStyle}
                options={mappedHingeStyles}
                image={selectedHinge?.image}
                selectFieldValue={setHingeStyle}
                placeholder={selectedHinge?.name}
                loading={loadingHingeStyles}
                isMiniEditor={isMiniEditor}
            />
            <HardwareSelectionField
                setting={settings}
                label="Select your default drawer style"
                moreInfo={MoreInfoData.drawerSystem}
                name="drawerSystem"
                value={values.drawerSystem}
                options={mappedDrawerSystems}
                image={selectedDrawer?.image}
                selectFieldValue={setDrawerSystem}
                placeholder={selectedDrawer?.name}
                loading={loadingDrawerSystems}
                isMiniEditor={isMiniEditor}
            />

            {userProfile.isAddProductAvailable ? (
                <HardwareSelectionField
                    setting={settings}
                    label="Select your default cabinet top (base cabinets)"
                    name="cabinetTop"
                    value={values.cabinetTop}
                    options={cabinetTops}
                    image={selectedCabinetTop?.image}
                    selectFieldValue={setCabinetTop}
                    placeholder={selectedCabinetTop?.name}
                    loading={loadingCabinetTops}
                    isMiniEditor={isMiniEditor}
                />
            ) : null}
        </div>
    );
};
