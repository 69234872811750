import {useField} from 'formik';
import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {formatPrice} from 'shared/helpers';
import {
    selectCost,
    selectFetchingCost,
    selectIsValid,
    selectUpdated,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {AppState} from 'store/customer/storeSetup';
import {usePriceDebounce} from 'components/customer/QFPRedux/helpers/usePriceDebounce';
import {Product} from 'components/customer/Product/entity/Product';
import {
    materialsLoading,
    selectDefaultLoaded,
} from 'components/customer/Materials/store/materialSlice';
import {MaterialType} from 'components/customer/Materials/entity';

interface PriceInterface {
    index: number;
    product: Product;
    unitPrice?: boolean;
    quantityField?: string;
    cost?: number;
    fetchingCost?: boolean;
    isValid?: boolean;
    defaultLoaded?: boolean;
    isUpdated?: boolean;
    areMaterialsLoading?: boolean;
}

const PriceComponent = ({
    index,
    product,
    unitPrice = true,
    quantityField = 'cabinet_quantity',
    cost,
    fetchingCost,
    isValid,
    defaultLoaded,
    isUpdated,
    areMaterialsLoading,
}: PriceInterface) => {
    const [field] = useField(quantityField);
    usePriceDebounce({
        product,
        isValid,
        unitPrice,
        index,
        defaultLoaded,
        isUpdated,
        areMaterialsLoading,
    });

    const price = useMemo(() => {
        if (unitPrice) {
            return formatPrice((cost ? cost : 0) / field.value, {});
        }

        return formatPrice(cost ? cost : 0, {});
    }, [unitPrice, cost]);

    return (
        <td
            style={{fontSize: '1rem'}}
            id={`${index}-price${unitPrice ? '-unit' : ''}`}>
            {fetchingCost ? '...' : price}
        </td>
    );
};

export const Price = connect((state: AppState, {index}: PriceInterface) => ({
    cost: selectCost(state, index),
    fetchingCost: selectFetchingCost(state, index),
    isValid: selectIsValid(state, index),
    isUpdated: selectUpdated(state, index),
    defaultLoaded: selectDefaultLoaded(state, MaterialType.EXTERIOR, index),
    areMaterialsLoading: materialsLoading(state, MaterialType.EXTERIOR, index),
}))(PriceComponent);
