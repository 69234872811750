import {User} from 'components/customer/Auth/store/userSlice';
import {PartialRoom} from 'shared/types/PartialRoom';
import {GapSize} from 'shared/types/GapSize';
import {ProductSize} from 'shared/types/ProductSize';
import {LegacyMaterialInterface} from 'components/customer/Materials/helper/materialHelper';
import {LegacyEdgeInterface} from 'components/customer/Materials/helper/edgeHelper';
import {LegacyDoorInterface} from 'components/customer/Materials/helper/doorHelper';
import {RoomData} from '../store/roomSlice';

interface RoomMaterialData {
    materials: {
        ext_material?: LegacyMaterialInterface;
        carc_material?: LegacyMaterialInterface;
        ext_edge?: LegacyEdgeInterface;
        carc_edge?: LegacyEdgeInterface;
        door?: LegacyDoorInterface;
    };
    room: RoomData;
}

export const getRoomData = ({
    room,
    user,
}: {
    room?: PartialRoom;
    user?: User;
}): RoomMaterialData => {
    if (room) {
        return {
            materials: {
                ext_material: room.extMaterial,
                carc_material: room.carcMaterial,
                ext_edge: room.extEdge,
                carc_edge: room.carcEdge,
                door: room.door,
            },
            room: {
                hingeStyle: room.hingeStyleDetails,
                drawerStyle: room.drawerSystem,
                cabinetTop: room.cabinetTop,
                productSize: {
                    id: Number(room.defaultSize),
                    baseDepth: Number(room.baseDepth),
                    baseHeight: Number(room.baseHeight),
                    tallDepth: Number(room.tallDepth),
                    tallHeight: Number(room.tallHeight),
                    upperDepth: Number(room.upperDepth),
                    upperHeight: Number(room.upperHeight),
                } as ProductSize,
                gapSize: {
                    id: Number(room.defaultGap),
                    baseBottomGap: Number(room.baseBottomGap),
                    baseTopGap: Number(room.baseTopGap),
                    doorGap: Number(room.doorGap),
                    drawerGap: Number(room.drawerGap),
                    left: Number(room.leftGap),
                    right: Number(room.rightGap),
                    upperBottomGap: Number(room.upperBottomGap),
                    upperTopGap: Number(room.upperTopGap),
                } as GapSize,
            },
        };
    } else {
        if (user && user.job && user.job.room) {
            return {
                materials: {
                    ext_material: user.job.room.exteriorMaterial,
                    carc_material: user.job.room.carcaseMaterial,
                    ext_edge: user.job.room.exteriorEdgeMaterial,
                    carc_edge: user.job.room.carcaseEdgeMaterial,
                    door: user.job.room.door,
                },
                room: {
                    hingeStyle: user.job.room.hingeStyle,
                    drawerStyle: user.job.room.drawerSystem,
                    cabinetTop: user.job.room.cabinetTop,
                    productSize: user.job.room.productSize,
                    gapSize: user.job.room.gapSize,
                },
            };
        }
    }

    return null;
};
