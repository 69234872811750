import {useAppDispatch} from 'store/customer';
import {
    costSet,
    fetchingCostSet,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {useDebouncedCallback} from 'use-debounce';
import {useEffect} from 'react';
import {Product} from 'components/customer/Product/entity/Product';
import {useLazyGetCostQuery} from 'components/customer/QFPRedux/store/qfpApi';
import {mapParamsForPriceRequest} from 'components/customer/QFPRedux/helpers/mapParamsforPriceRequest';
import {useMapDrawerFaceType} from 'components/customer/QFPRedux/helpers/useMapDrawerFaceType';
import {cloneDeep} from 'lodash';

interface PriceDebounceInterfae {
    product: Product;
    isValid: boolean;
    unitPrice: boolean;
    index: number;
    defaultLoaded: boolean;
    isUpdated: boolean;
    areMaterialsLoading: boolean;
}

export const usePriceDebounce = ({
    product,
    isValid,
    unitPrice,
    index,
    defaultLoaded,
    isUpdated,
    areMaterialsLoading,
}: PriceDebounceInterfae) => {
    const mapDrawer = useMapDrawerFaceType();
    const dispatch = useAppDispatch();
    const [getCost] = useLazyGetCostQuery();

    const fetchCost = useDebouncedCallback(async (product: Product) => {
        const drawerMapped = await mapDrawer(cloneDeep(product));
        const parameter = mapParamsForPriceRequest(drawerMapped);

        if (parameter.cabinet_width == '' || parameter.cabinet_height == '') {
            dispatch(costSet(0, index));
            dispatch(fetchingCostSet(false, index));
            return;
        }

        try {
            const response = await getCost(parameter, true);

            if (response && response.data) {
                dispatch(costSet(response.data, index));
            }
        } catch (e) {
            // console.error(e);
        } finally {
            dispatch(fetchingCostSet(false, index));
        }
    }, 1000);

    useEffect(() => {
        if (!unitPrice) return;
        if (!isUpdated) return;
        if (!defaultLoaded) return;
        if (!isValid) return;
        if (areMaterialsLoading) return;
        if (typeof product.cabinet_door === 'undefined') {
            return;
        }

        dispatch(fetchingCostSet(true, index));
        void fetchCost(product);
    }, [product, defaultLoaded, isValid, areMaterialsLoading]);
};
