import {useState, MutableRefObject, useMemo} from 'react';

interface Position {
    x: number;
    y: number;
}

interface ImagePosition {
    shelfSetBack: Position;
    tallCarcaseHeight: Position;
    tallCarcaseDepth: Position;
    upperCarcaseHeight: Position;
    upperCarcaseDepth: Position;
    baseCarcaseHeight: Position;
    baseCarcaseDepth: Position;
    toeKickHeight: Position;
}

export const useProductSizeDefaultPreview = (
    image: MutableRefObject<HTMLImageElement>,
    container: MutableRefObject<HTMLDivElement>
) => {
    const [loaded, setLoaded] = useState(false);
    const positions: ImagePosition = useMemo(() => {
        if (loaded) {
            if (container && image && container.current && image.current) {
                const containerDimension =
                    container.current.getBoundingClientRect();
                const dimension = image.current.getBoundingClientRect();

                const width = dimension.width;
                const height = dimension.height;
                const xOffset = dimension.left - containerDimension.left;

                return {
                    shelfSetBack: {
                        x: xOffset + Math.round(0.165 * width),
                        y: Math.round(0.64 * height),
                    },
                    tallCarcaseHeight: {
                        x: xOffset - 12,
                        y: Math.round(0.4 * height),
                    },
                    tallCarcaseDepth: {
                        x: xOffset + Math.round(0.245 * width),
                        y: Math.round(0.44 * height),
                    },
                    upperCarcaseHeight: {
                        x: xOffset + Math.round(0.86 * width),
                        y: Math.round(0.15 * height),
                    },
                    upperCarcaseDepth: {
                        x: xOffset + Math.round(0.815 * width),
                        y: Math.round(0.34 * height),
                    },
                    baseCarcaseHeight: {
                        x: xOffset + Math.round(0.86 * width),
                        y: Math.round(0.72 * height),
                    },
                    baseCarcaseDepth: {
                        x: xOffset + Math.round(0.762 * width),
                        y: Math.round(0.6 * height),
                    },
                    toeKickHeight: {
                        x: xOffset + Math.round(0.89 * width),
                        y: Math.round(0.855 * height),
                    },
                };
            }
        }
    }, [loaded]);

    return {
        positions,
        setLoaded,
    };
};
