import {makeZeroIfUndefined} from 'components/customer/Preview3D/helpers';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import {Drawer, PreviewFormValues} from 'Preview3D/types';
import {RoomPlannerFormValues} from 'components/customer/RoomPlanner/types';

type FormValues = {
    width: number;
    doorGap: number;
    height: number;
    doorTop: number;
    doorBottom: number;
    doorLeft: number;
    doorRight: number;
    drawerTop: number;
    returnPanelWidth: number;
    cabinetVoidWidth: number;
    leftWidth: number;
    rightDepth: number;
    rightWidth: number;
    leftDepth: number;
    drawers: Drawer[];
    microwaveOpeningHeight: number;
};

export const getDoorFormValues = (values: PreviewFormValues) => {
    return {
        width: makeZeroIfUndefined(values.cabinet_width),
        height: makeZeroIfUndefined(values.cabinet_height),
        doorGap: makeZeroIfUndefined(values.cabinet_door_gap),
        doorTop: makeZeroIfUndefined(values.cabinet_door_top),
        doorBottom: makeZeroIfUndefined(values.cabinet_door_bottom),
        doorLeft: makeZeroIfUndefined(values.cabinet_door_left),
        doorRight: makeZeroIfUndefined(values.cabinet_door_right),
        drawerTop: makeZeroIfUndefined(values.cabinet_drawer_top),
        returnPanelWidth: makeZeroIfUndefined(
            values.cabinet_return_panel_width
        ),
        cabinetVoidWidth:
            values.cabinet_void_width && Boolean(values.cabinet_cover_void)
                ? makeZeroIfUndefined(values.cabinet_void_width)
                : 0,
        drawers: values.drawers,
        leftWidth: makeZeroIfUndefined(values.cabinet_left_width),
        rightDepth: makeZeroIfUndefined(values.cabinet_right_depth),
        rightWidth: makeZeroIfUndefined(values.cabinet_right_width),
        leftDepth: makeZeroIfUndefined(values.cabinet_left_depth),
        microwaveOpeningHeight: makeZeroIfUndefined(
            values.microwave_opening_height
        ),
    };
};

export const generateDoorPosition = (
    variables: TemplateVariable,
    values: RoomPlannerFormValues,
    formValues?: FormValues
) => {
    const doorBottomMargin = formValues.doorBottom - 1;
    const doorLeftMargin = formValues.doorLeft;
    const doorRightMargin = formValues.doorRight;

    const carcaseThickness = values.cabinet_carc_thickness;
    const exteriorThickness = values.cabinet_ext_thickness;

    const doorWidth = formValues.doorGap
        ? (formValues.width + carcaseThickness) / 2 - formValues.doorGap / 2
        : (formValues.width + carcaseThickness) / 2;
    const singleDoorWidth =
        formValues.width + carcaseThickness - doorRightMargin - doorLeftMargin;

    const cornerHangDoorWidth =
        (formValues.leftWidth - formValues.rightDepth) * 1.42 +
        2 -
        doorLeftMargin;

    const thicknessDiff = exteriorThickness - carcaseThickness;
    const singleDoorLocation = singleDoorWidth + doorLeftMargin;
    const rightBifoldDoorWidth = formValues.rightWidth - formValues.leftDepth;
    const leftBifoldDoorWidth = formValues.leftWidth - formValues.rightDepth;

    return {
        leftDoorWidth: doorWidth - doorLeftMargin + 1,
        rightDoorWidth: doorWidth - doorRightMargin + 1,
        rightDoorLocation: doorWidth + formValues.doorGap + 1,
        leftDoorLocation: doorWidth - 1,
        doorHeight:
            formValues.height -
            formValues.doorTop -
            doorBottomMargin -
            (variables?.isWallOvenProduct
                ? Number(values.cabinet_total_drawer_height) +
                  Number(values.oven_opening_height) +
                  formValues.microwaveOpeningHeight
                : 0),
        doorBottomMargin:
            doorBottomMargin +
            (variables?.isWallOvenProduct
                ? Number(values.cabinet_total_drawer_height) +
                  Number(values.oven_opening_height) +
                  formValues.microwaveOpeningHeight
                : 0),
        doorLeftMargin,
        singleDoorWidth: singleDoorWidth + 1,
        singleDoorLocation: singleDoorLocation - 1,
        singleDoorLocationRightHang: singleDoorLocation + 1,
        returnPanelWidth: formValues.returnPanelWidth,
        leftBifoldDoorWidth:
            leftBifoldDoorWidth - doorLeftMargin - thicknessDiff,
        rightBifoldDoorWidth:
            rightBifoldDoorWidth - doorRightMargin - thicknessDiff,
        rightBifoldDoorX: formValues.leftDepth + exteriorThickness,
        rightBifoldDoorY: formValues.leftWidth - formValues.rightDepth,
        leftBifoldDoorY: -carcaseThickness + doorLeftMargin,
        cornerHangDoorWidth,
        cornerPairDoorWidth: cornerHangDoorWidth / 2 - formValues.doorGap,
        leftCornerDoorX: formValues.leftDepth,
        rightCornerDoorX: formValues.leftDepth + 2,
        rightCornerDoorY: -exteriorThickness,
        doorGap: formValues.doorGap,
    };
};

export const generateReturnDoor = (
    variables: TemplateVariable,
    values: RoomPlannerFormValues,
    formValues?: FormValues
) => {
    const doorLeftMargin = formValues.doorLeft;
    const doorRightMargin = formValues.doorRight;
    const carcaseThickness = values.cabinet_carc_thickness;

    const doorDefault = generateDoorPosition(variables, values, formValues);
    const width = formValues.width - doorDefault.returnPanelWidth;

    const doorWidth = formValues.doorGap
        ? (width + carcaseThickness) / 2 - formValues.doorGap / 2
        : (width + carcaseThickness) / 2;

    const voidWidthHalf = formValues.cabinetVoidWidth
        ? formValues.cabinetVoidWidth / 2
        : 0;
    const leftDoorWidth = doorWidth - doorLeftMargin + voidWidthHalf;
    const rightDoorWidth = doorWidth - doorRightMargin + voidWidthHalf;
    const singleDoorWidth =
        formValues.width -
        doorDefault.returnPanelWidth +
        carcaseThickness -
        doorRightMargin -
        doorLeftMargin +
        formValues.cabinetVoidWidth;
    const singleDoorLocation =
        singleDoorWidth + doorLeftMargin + doorDefault.returnPanelWidth;

    return {
        ...doorDefault,
        leftDoorWidth,
        rightDoorWidth,
        rightDoorLocation:
            doorWidth +
            doorDefault.returnPanelWidth +
            formValues.doorGap -
            voidWidthHalf +
            2,
        leftDoorLocation:
            doorWidth + doorDefault.returnPanelWidth - voidWidthHalf - 2,
        singleDoorWidth,
        singleDoorLocation: singleDoorLocation - formValues.cabinetVoidWidth,
        returnPanelLocation:
            doorDefault.returnPanelWidth - formValues.cabinetVoidWidth,
        rightReturn: {
            leftDoorWidth,
            rightDoorWidth,
            rightDoorLocation:
                doorWidth +
                formValues.doorGap +
                formValues.cabinetVoidWidth -
                voidWidthHalf,
            leftDoorLocation: doorWidth + voidWidthHalf,
            returnPanelLocation:
                doorDefault.returnPanelWidth +
                leftDoorWidth +
                rightDoorWidth +
                doorLeftMargin +
                doorRightMargin +
                formValues.doorGap,
            singleDoorWidth,
            singleDoorLocation:
                singleDoorLocation - doorDefault.returnPanelWidth,
        },
    };
};

export const generateApplianceDoor = (
    variables: TemplateVariable,
    values: RoomPlannerFormValues,
    formValues?: FormValues
) => {
    const doorDefault = generateDoorPosition(variables, values, formValues);
    const carcaseThickness = values.cabinet_carc_thickness;
    const width = formValues.width + carcaseThickness;
    const doorRightMargin = formValues.doorRight;
    const doorLeftMargin = formValues.doorLeft;
    const doorTopMargin = formValues.doorTop;
    const doorGap = formValues.doorGap;

    const fasciaWidth = 28;
    const threeDoorWidth = (width - doorGap * 2) / 3;
    const fourDoorWidth = (width - doorGap * 3) / 4;

    let leftBifoldDoorWidth = threeDoorWidth - doorLeftMargin;
    let leftBifoldDoorLocation = threeDoorWidth;
    let rightBifoldDoorWidth = threeDoorWidth + 1;
    let rightBifoldDoorLocation = threeDoorWidth + doorGap + 2;

    if (variables?.isApplianceLeftRightBifold) {
        leftBifoldDoorWidth = threeDoorWidth + 1;
        leftBifoldDoorLocation = threeDoorWidth * 2 + doorGap;
        rightBifoldDoorWidth = threeDoorWidth - doorRightMargin + 1;
        rightBifoldDoorLocation = width - threeDoorWidth + 2;
    }

    const isApplianceComplexBifold =
        variables?.isApplianceLeftBifoldRight ||
        variables?.isApplianceLeftRightBifold;

    return {
        ...doorDefault,
        rightDoorWidth: variables?.isApplianceLeftBifoldRight
            ? threeDoorWidth - doorRightMargin
            : doorDefault.rightDoorWidth,
        rightDoorLocation: width - threeDoorWidth + 2,
        leftDoorWidth: variables?.isApplianceLeftRightBifold
            ? threeDoorWidth - doorLeftMargin + 2
            : doorDefault.leftDoorWidth,
        leftDoorLocation: threeDoorWidth,
        bifold: {
            leftDoorWidth: isApplianceComplexBifold
                ? leftBifoldDoorWidth
                : doorDefault.leftDoorWidth,
            leftDoorLocation: isApplianceComplexBifold
                ? leftBifoldDoorLocation
                : doorDefault.leftDoorLocation,
            rightDoorWidth: isApplianceComplexBifold
                ? rightBifoldDoorWidth
                : doorDefault.rightDoorWidth,
            rightDoorLocation: isApplianceComplexBifold
                ? rightBifoldDoorLocation
                : doorDefault.rightDoorLocation,
        },
        ...(variables?.isApplianceBifoldPair
            ? {
                  bifoldPair: {
                      leftPairLeftWidth: fourDoorWidth - doorLeftMargin + 1,
                      leftPairLeftLocation: fourDoorWidth - 1,

                      leftPairRightWidth: fourDoorWidth,
                      leftPairRightLocation: fourDoorWidth + doorGap + 1,

                      rightPairLeftWidth: fourDoorWidth + 1,
                      rightPairLeftLocation:
                          fourDoorWidth * 3 + doorGap * 2 - 2,

                      rightPairRightWidth: fourDoorWidth - doorRightMargin + 2,
                      rightPairRightLocation: fourDoorWidth * 3 + doorGap * 3,
                  },
              }
            : {}),
        fascia: {
            topWidth: formValues.width - carcaseThickness - fasciaWidth * 2 + 3,
            topHeight:
                carcaseThickness +
                fasciaWidth -
                (doorTopMargin < 0 ? doorTopMargin : 0),
            topPositionX: carcaseThickness + fasciaWidth - 2,
            topPositionZ:
                formValues.height -
                carcaseThickness -
                fasciaWidth -
                (doorTopMargin < 0 ? 0 : doorTopMargin),
            leftWidth: carcaseThickness + fasciaWidth - doorLeftMargin,
            rightWidth: carcaseThickness + fasciaWidth - doorRightMargin,
            leftPositionX: carcaseThickness + fasciaWidth,
            rightPositionX: formValues.width - fasciaWidth,
            sidePozitionZ: -1,
            doorHeight: formValues.height - formValues.doorTop + 1,
        },
    };
};

export const generateDoorDrawer = (formValues?: FormValues) => {
    if (!formValues.drawers) return {};

    const faceHeight = formValues.drawers[0].drawer_face_height;
    const doorBottomMargin = formValues.doorBottom;

    return {
        doorHeight:
            formValues.height -
            formValues.doorTop -
            doorBottomMargin -
            (faceHeight + formValues.drawerTop),
    };
};
