import React from 'react';
import {Form, Row, Col, Image} from 'react-bootstrap';
import {MoreInfo} from 'shared';
import {CabinetTop} from 'shared/types/CabinetTop';
import {DrawerSystem} from 'shared/types/DrawerSystem';
import {HingeStyle} from 'shared/types/HingeStyle';
import styled from 'styled-components';
import {isDeviceSmall, useTabletSize} from 'shared/helpers/DeviceSize';
import SelectField from 'shared/components/SelectField';

interface HardwareSelectionFieldProps {
    setting: boolean;
    label: string;
    moreInfo?: number;
    name: string;
    value: number;
    options: CabinetTop[] | HingeStyle[] | DrawerSystem[];
    image: string;
    selectFieldValue: (name: string, value: number) => void;
    placeholder: string;
    loading: boolean;
    isMiniEditor?: boolean;
}

export const HardwareSelectionField = ({
    setting,
    label,
    moreInfo,
    name,
    options,
    image,
    placeholder,
    selectFieldValue,
    loading,
    isMiniEditor = false,
}: HardwareSelectionFieldProps) => {
    const isSmallDevice = isDeviceSmall();
    const isTabletSize = useTabletSize();
    const stackedFields = isTabletSize || isSmallDevice;

    return (
        <FormGroup
            $isMiniEditor={isMiniEditor}
            className="form-group"
            as={Row}
            $setting={setting || stackedFields}>
            <Form.Label
                column
                md={isMiniEditor ? 12 : isTabletSize ? 9 : {offset: 0, span: 3}}
                sm={isMiniEditor ? 12 : isTabletSize ? 9 : {offset: 1, span: 5}}
                xs={12}>
                <div style={{display: stackedFields ? 'flex' : 'initial'}}>
                    {label}: <span>*</span>
                    {setting ? null : typeof moreInfo === 'undefined' ? null : (
                        <MoreInfo
                            info={moreInfo}
                            dynamicposition={stackedFields}
                        />
                    )}
                </div>
            </Form.Label>
            <Col
                md={isMiniEditor ? 12 : isTabletSize ? 9 : {offset: 1, span: 4}}
                sm={isMiniEditor ? 12 : isTabletSize ? 9 : 5}
                xs={12}>
                <SelectField
                    name={name}
                    placeholder={placeholder}
                    options={options?.map(({id, name, disabled}) => ({
                        label: name,
                        value: String(id),
                        disabled,
                    }))}
                    isLoading={loading}
                    customOnChange={selectFieldValue}
                />
            </Col>
            <Col
                md={isMiniEditor ? 8 : isTabletSize ? 9 : {offset: 1, span: 3}}
                sm={isMiniEditor ? 8 : isTabletSize ? 9 : 5}
                xs={12}>
                {isMiniEditor ? <br /> : null}
                {image ? (
                    <Image className="hingeImage" src={`/${image}`} fluid />
                ) : null}
            </Col>
        </FormGroup>
    );
};

const FormGroup = styled(Form.Group)<{
    $setting: boolean;
    $isMiniEditor?: boolean;
}>`
    align-items: center;
    justify-content: ${({$setting, $isMiniEditor}) =>
        $isMiniEditor ? 'center' : $setting ? 'flex-start' : 'flex-end'};
    margin-bottom: 15px;

    > label {
        position: relative;
    }
`;
