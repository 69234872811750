import React, {useCallback} from 'react';
import {Col, Form, Image, Row} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {isDeviceSmall, useTabletSize} from 'shared/helpers/DeviceSize';
import {MoreInfoData, MoreInfo} from 'shared';
import {useFormikContext} from 'formik';
import {DoubleBase, SingleBase, Drawer, DoubleUpper} from 'assets';
import {shallowEqual} from 'react-redux';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {WizardComponent} from 'shared/components/Wizard/entity/WizardComponent';
import {useGetGapSizeDefaultsQuery} from 'components/customer/Room/store/roomApi';
import {
    gapSizeSet,
    selectGapSize,
} from 'components/customer/Room/store/roomSlice';
import {useGapSizeDefaultPreview} from 'components/customer/Room/helpers/useGapSizeDefaultPreview';
import {
    SizeDefaultField,
    SizeDefaultsFields,
} from 'components/customer/Room/SizeDefaultField';
import {RoomFields} from 'components/customer/Room/RoomComponents';
import SelectField from 'shared/components/SelectField';
import {FormGroup} from 'shared/components/Forms/FormGroup';

export const GapSizeDefaults = ({}: WizardComponent) => {
    const dispatch = useAppDispatch();
    const {
        data: gapSizes,
        isLoading,
        isFetching,
    } = useGetGapSizeDefaultsQuery();
    const {setFieldValue} = useFormikContext<RoomFields>();
    const isSmallDevice = isDeviceSmall();
    const {fade, position, focusAction} = useGapSizeDefaultPreview();
    const gapSize = useAppSelector(selectGapSize, shallowEqual);
    const isTabletSize = useTabletSize();

    const setGapSizeValue = useCallback(
        (name: string, value: string) => {
            void setFieldValue(name, value);

            const gapSize = gapSizes?.find((size) => String(size.id) == value);

            if (gapSize) {
                dispatch(gapSizeSet(gapSize));

                void setFieldValue('doorGap', gapSize.doorGap);
                void setFieldValue('drawerGap', gapSize.drawerGap);
                void setFieldValue('leftGap', gapSize.left);
                void setFieldValue('rightGap', gapSize.right);
                void setFieldValue('upperTopMargins', gapSize.upperTopGap);
                void setFieldValue(
                    'upperBottomMargins',
                    gapSize.upperBottomGap
                );
                void setFieldValue('baseTopMargins', gapSize.baseBottomGap);
                void setFieldValue('baseBottomMargins', gapSize.baseBottomGap);
            }
        },
        [gapSizes]
    );

    const setFocus = useCallback(
        (imageId: SizeDefaultsFields, inputId: SizeDefaultsFields) => () =>
            focusAction(imageId, inputId),
        []
    );

    return (
        <div className="relativePos">
            <Row>
                <Col md={isTabletSize ? 12 : 6} xs={12}>
                    <FormGroup controlId="gapDefaults_">
                        <Form.Label column md={3} xs={6}>
                            Gap Defaults:
                            <MoreInfo info={MoreInfoData.gapDefaults} />
                        </Form.Label>
                        <Col md={6} xs={6}>
                            <SelectField
                                name="gapDefaults"
                                placeholder={gapSize?.name}
                                options={gapSizes?.map(({id, name}) => ({
                                    label: name,
                                    value: String(id),
                                }))}
                                customOnChange={setGapSizeValue}
                                isLoading={isLoading || isFetching}
                            />
                        </Col>
                    </FormGroup>

                    <hr style={{margin: '15px 0'}} />

                    <Row style={{alignItems: 'center'}}>
                        <Col md={6} xs={8}>
                            <SizeDefaultField
                                label="Door Gap"
                                name="doorGap"
                                moreInfo={MoreInfoData.doorGap}
                                field={SizeDefaultsFields.DOOR_GAP}
                                selectedField={fade}
                                setField={setFocus(
                                    SizeDefaultsFields.DOOR_GAP,
                                    SizeDefaultsFields.DOOR_GAP
                                )}
                                labelSpan={{span: 6}}
                                inputSpan={{span: 6}}
                            />
                        </Col>
                        <Col
                            md={6}
                            xs={4}
                            style={{textAlign: 'center'}}
                            className="gap-container"
                            id={`${SizeDefaultsFields.DOOR_GAP}_container_id`}>
                            {position.hasOwnProperty(
                                SizeDefaultsFields.DOOR_GAP
                            ) ? (
                                <>
                                    <div
                                        className="gap-size-default-line"
                                        style={{
                                            width: '2px',
                                            height: position[
                                                SizeDefaultsFields.DOOR_GAP
                                            ].lineHeight,
                                            top: position[
                                                SizeDefaultsFields.DOOR_GAP
                                            ].lineY,
                                            left: position[
                                                SizeDefaultsFields.DOOR_GAP
                                            ].lineX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.DOOR_GAP
                                                    ? 0.6
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields.DOOR_GAP
                                            ].arrowWidth,
                                            transform: 'rotate(270deg)',
                                            top: position[
                                                SizeDefaultsFields.DOOR_GAP
                                            ].topArrowY,
                                            left: position[
                                                SizeDefaultsFields.DOOR_GAP
                                            ].arrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.DOOR_GAP
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields.DOOR_GAP
                                            ].arrowWidth,
                                            transform: 'rotate(90deg)',
                                            top: position[
                                                SizeDefaultsFields.DOOR_GAP
                                            ].bottomArrowY,
                                            left: position[
                                                SizeDefaultsFields.DOOR_GAP
                                            ].arrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.DOOR_GAP
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                </>
                            ) : null}

                            <Image
                                id={`${SizeDefaultsFields.DOOR_GAP}_image_id`}
                                style={{
                                    width: isSmallDevice ? '90%' : '78%',
                                }}
                                src={String(DoubleBase)}
                                alt="Base door gap"
                            />
                        </Col>
                    </Row>

                    <hr style={{margin: '15px 0'}} />
                    <Row style={{alignItems: 'center'}}>
                        <Col md={6} xs={8}>
                            <SizeDefaultField
                                label="Drawer Gap"
                                name="drawerGap"
                                moreInfo={MoreInfoData.drawerGap}
                                field={SizeDefaultsFields.DRAWER_GAP}
                                selectedField={fade}
                                setField={setFocus(
                                    SizeDefaultsFields.DRAWER_GAP,
                                    SizeDefaultsFields.DRAWER_GAP
                                )}
                                labelSpan={{span: 6}}
                                inputSpan={{span: 6}}
                            />
                        </Col>
                        <Col
                            md={6}
                            xs={4}
                            style={{textAlign: 'center'}}
                            className="gap-container"
                            id={`${SizeDefaultsFields.DRAWER_GAP}_container_id`}>
                            {position.hasOwnProperty(
                                SizeDefaultsFields.DRAWER_GAP
                            ) ? (
                                <>
                                    <div
                                        className="gap-size-default-line"
                                        style={{
                                            height: '2px',
                                            transform: 'rotate(13.2deg)',
                                            width: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].lineWidth,
                                            top: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].topLineY,
                                            left: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].lineX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.DRAWER_GAP
                                                    ? 0.6
                                                    : 0,
                                        }}
                                    />
                                    <div
                                        className="gap-size-default-line"
                                        style={{
                                            height: '2px',
                                            transform: 'rotate(17.2deg)',
                                            width: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].lineWidth,
                                            top: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].bottomLineY,
                                            left: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].lineX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.DRAWER_GAP
                                                    ? 0.6
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].arrowWidth,
                                            transform: 'rotate(180deg)',
                                            top: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].topArrowY1,
                                            left: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].arrowX1,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.DRAWER_GAP
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].arrowWidth,
                                            transform: 'rotate(180deg)',
                                            top: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].bottomArrowY1,
                                            left: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].arrowX1,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.DRAWER_GAP
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].arrowWidth,
                                            top: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].topArrowY2,
                                            left: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].arrowX2,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.DRAWER_GAP
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].arrowWidth,
                                            top: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].bottomArrowY2,
                                            left: position[
                                                SizeDefaultsFields.DRAWER_GAP
                                            ].arrowX2,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.DRAWER_GAP
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                </>
                            ) : null}
                            <Image
                                id={`${SizeDefaultsFields.DRAWER_GAP}_image_id`}
                                style={{
                                    width: isSmallDevice ? '90%' : '78%',
                                }}
                                src={String(Drawer)}
                                alt="Base door gap"
                            />
                        </Col>
                    </Row>

                    {isSmallDevice ? <hr style={{margin: '15px 0'}} /> : null}
                </Col>

                <Col md={isTabletSize ? 12 : 6} xs={12}>
                    <Row style={{alignItems: 'center'}}>
                        <Col md={6} xs={8}>
                            <SizeDefaultField
                                label="Left Gap"
                                name="leftGap"
                                moreInfo={MoreInfoData.leftGap}
                                field={SizeDefaultsFields.LEFT_GAP}
                                selectedField={fade}
                                setField={setFocus(
                                    SizeDefaultsFields.GAP,
                                    SizeDefaultsFields.LEFT_GAP
                                )}
                                labelSpan={{span: 6}}
                                inputSpan={{span: 6}}
                            />
                            <SizeDefaultField
                                label="Right Gap"
                                name="rightGap"
                                moreInfo={MoreInfoData.rightGap}
                                field={SizeDefaultsFields.RIGHT_GAP}
                                selectedField={fade}
                                setField={setFocus(
                                    SizeDefaultsFields.GAP,
                                    SizeDefaultsFields.RIGHT_GAP
                                )}
                                labelSpan={{span: 6}}
                                inputSpan={{span: 6}}
                            />
                        </Col>
                        <Col
                            md={6}
                            xs={4}
                            style={{textAlign: 'center'}}
                            className="gap-container"
                            id={`${SizeDefaultsFields.GAP}_container_id`}>
                            {position.hasOwnProperty(
                                SizeDefaultsFields.LEFT_GAP
                            ) ? (
                                <>
                                    <div
                                        className="gap-size-default-line"
                                        style={{
                                            width: '2px',
                                            transform: 'rotate(-2deg)',
                                            height: position[
                                                SizeDefaultsFields.LEFT_GAP
                                            ].lineHeight,
                                            top: position[
                                                SizeDefaultsFields.LEFT_GAP
                                            ].lineY,
                                            left: position[
                                                SizeDefaultsFields.LEFT_GAP
                                            ].lineX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.LEFT_GAP
                                                    ? 0.6
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: '20px',
                                            transform: 'rotate(270deg)',
                                            top: position[
                                                SizeDefaultsFields.LEFT_GAP
                                            ].topArrowY,
                                            left: position[
                                                SizeDefaultsFields.LEFT_GAP
                                            ].arrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.LEFT_GAP
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: '20px',
                                            transform: 'rotate(90deg)',
                                            top: position[
                                                SizeDefaultsFields.LEFT_GAP
                                            ].bottomArrowY,
                                            left: position[
                                                SizeDefaultsFields.LEFT_GAP
                                            ].arrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.LEFT_GAP
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                </>
                            ) : null}

                            {position.hasOwnProperty(
                                SizeDefaultsFields.RIGHT_GAP
                            ) ? (
                                <>
                                    <div
                                        className="gap-size-default-line"
                                        style={{
                                            width: '2px',
                                            transform: 'rotate(.6deg)',
                                            height: position[
                                                SizeDefaultsFields.RIGHT_GAP
                                            ].lineHeight,
                                            top: position[
                                                SizeDefaultsFields.RIGHT_GAP
                                            ].lineY,
                                            left: position[
                                                SizeDefaultsFields.RIGHT_GAP
                                            ].lineX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.RIGHT_GAP
                                                    ? 0.6
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: '20px',
                                            transform: 'rotate(270deg)',
                                            top: position[
                                                SizeDefaultsFields.RIGHT_GAP
                                            ].topArrowY,
                                            left: position[
                                                SizeDefaultsFields.RIGHT_GAP
                                            ].arrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.RIGHT_GAP
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: '20px',
                                            transform: 'rotate(90deg)',
                                            top: position[
                                                SizeDefaultsFields.RIGHT_GAP
                                            ].bottomArrowY,
                                            left: position[
                                                SizeDefaultsFields.RIGHT_GAP
                                            ].arrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.RIGHT_GAP
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                </>
                            ) : null}
                            <Image
                                id={`${SizeDefaultsFields.GAP}_image_id`}
                                style={{
                                    width: isSmallDevice ? '80%' : '80px',
                                }}
                                src={String(SingleBase)}
                                alt="Base door gap"
                            />
                        </Col>
                    </Row>

                    <hr style={isSmallDevice ? {margin: '15px 0'} : {}} />

                    <Row style={{alignItems: 'center'}}>
                        <Col md={6} xs={8}>
                            <SizeDefaultField
                                label="Upper Top Margins"
                                name="upperTopMargins"
                                moreInfo={MoreInfoData.upperTopMargin}
                                field={SizeDefaultsFields.UPPER_TOP_MARGIN}
                                selectedField={fade}
                                setField={setFocus(
                                    SizeDefaultsFields.UPPER_MARGIN,
                                    SizeDefaultsFields.UPPER_TOP_MARGIN
                                )}
                                labelSpan={{span: 6}}
                                inputSpan={{span: 6}}
                            />

                            <SizeDefaultField
                                label="Upper Bottom Margins"
                                name="upperBottomMargins"
                                moreInfo={MoreInfoData.upperBottomMargin}
                                field={SizeDefaultsFields.UPPER_BOTTOM_MARGIN}
                                selectedField={fade}
                                setField={setFocus(
                                    SizeDefaultsFields.UPPER_MARGIN,
                                    SizeDefaultsFields.UPPER_BOTTOM_MARGIN
                                )}
                                labelSpan={{span: 6}}
                                inputSpan={{span: 6}}
                            />
                        </Col>
                        <Col
                            md={6}
                            xs={4}
                            style={{textAlign: 'center'}}
                            className="gap-container"
                            id={`${SizeDefaultsFields.UPPER_MARGIN}_container_id`}>
                            {position.hasOwnProperty(
                                SizeDefaultsFields.UPPER_TOP_MARGIN
                            ) ? (
                                <>
                                    <div
                                        className="gap-size-default-line"
                                        style={{
                                            height: '2px',
                                            width: position[
                                                SizeDefaultsFields
                                                    .UPPER_TOP_MARGIN
                                            ].lineWidth,
                                            top: position[
                                                SizeDefaultsFields
                                                    .UPPER_TOP_MARGIN
                                            ].lineY,
                                            left: position[
                                                SizeDefaultsFields
                                                    .UPPER_TOP_MARGIN
                                            ].lineX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.UPPER_TOP_MARGIN
                                                    ? 0.6
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields
                                                    .UPPER_TOP_MARGIN
                                            ].arrowWidth,
                                            transform: 'rotate(180deg)',
                                            top: position[
                                                SizeDefaultsFields
                                                    .UPPER_TOP_MARGIN
                                            ].arrowY,
                                            left: position[
                                                SizeDefaultsFields
                                                    .UPPER_TOP_MARGIN
                                            ].leftArrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.UPPER_TOP_MARGIN
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields
                                                    .UPPER_TOP_MARGIN
                                            ].arrowWidth,
                                            top: position[
                                                SizeDefaultsFields
                                                    .UPPER_TOP_MARGIN
                                            ].arrowY,
                                            left: position[
                                                SizeDefaultsFields
                                                    .UPPER_TOP_MARGIN
                                            ].rightArrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.UPPER_TOP_MARGIN
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                </>
                            ) : null}

                            {position.hasOwnProperty(
                                SizeDefaultsFields.UPPER_BOTTOM_MARGIN
                            ) ? (
                                <>
                                    <div
                                        className="gap-size-default-line"
                                        style={{
                                            height: '2px',
                                            width: position[
                                                SizeDefaultsFields
                                                    .UPPER_BOTTOM_MARGIN
                                            ].lineWidth,
                                            top: position[
                                                SizeDefaultsFields
                                                    .UPPER_BOTTOM_MARGIN
                                            ].lineY,
                                            left: position[
                                                SizeDefaultsFields
                                                    .UPPER_BOTTOM_MARGIN
                                            ].lineX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.UPPER_BOTTOM_MARGIN
                                                    ? 0.6
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields
                                                    .UPPER_BOTTOM_MARGIN
                                            ].arrowWidth,
                                            transform: 'rotate(180deg)',
                                            top: position[
                                                SizeDefaultsFields
                                                    .UPPER_BOTTOM_MARGIN
                                            ].arrowY,
                                            left: position[
                                                SizeDefaultsFields
                                                    .UPPER_BOTTOM_MARGIN
                                            ].leftArrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.UPPER_BOTTOM_MARGIN
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields
                                                    .UPPER_BOTTOM_MARGIN
                                            ].arrowWidth,
                                            top: position[
                                                SizeDefaultsFields
                                                    .UPPER_BOTTOM_MARGIN
                                            ].arrowY,
                                            left: position[
                                                SizeDefaultsFields
                                                    .UPPER_BOTTOM_MARGIN
                                            ].rightArrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.UPPER_BOTTOM_MARGIN
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                </>
                            ) : null}
                            <Image
                                id={`${SizeDefaultsFields.UPPER_MARGIN}_image_id`}
                                style={{width: '90%'}}
                                src={String(DoubleUpper)}
                                alt="Base door gap"
                            />
                        </Col>
                    </Row>

                    <hr style={isSmallDevice ? {margin: '15px 0'} : {}} />

                    <Row style={{alignItems: 'center'}}>
                        <Col md={6} xs={8}>
                            <SizeDefaultField
                                label="Base Top Margins"
                                name="baseTopMargins"
                                moreInfo={MoreInfoData.baseTopMargin}
                                field={SizeDefaultsFields.BASE_TOP_MARGIN}
                                selectedField={fade}
                                setField={setFocus(
                                    SizeDefaultsFields.BASE_MARGIN,
                                    SizeDefaultsFields.BASE_TOP_MARGIN
                                )}
                                labelSpan={{span: 6}}
                                inputSpan={{span: 6}}
                            />
                            <SizeDefaultField
                                label="Base Bottom Margins"
                                name="baseBottomMargins"
                                moreInfo={MoreInfoData.baseBottomMargin}
                                field={SizeDefaultsFields.BASE_BOTTOM_MARGIN}
                                selectedField={fade}
                                setField={setFocus(
                                    SizeDefaultsFields.BASE_MARGIN,
                                    SizeDefaultsFields.BASE_BOTTOM_MARGIN
                                )}
                                labelSpan={{span: 6}}
                                inputSpan={{span: 6}}
                            />
                        </Col>
                        <Col
                            md={6}
                            xs={4}
                            style={{textAlign: 'center'}}
                            className="gap-container"
                            id={`${SizeDefaultsFields.BASE_MARGIN}_container_id`}>
                            {position.hasOwnProperty(
                                SizeDefaultsFields.BASE_TOP_MARGIN
                            ) ? (
                                <>
                                    <div
                                        className="gap-size-default-line"
                                        style={{
                                            height: '2px',
                                            width: position[
                                                SizeDefaultsFields
                                                    .BASE_TOP_MARGIN
                                            ].lineWidth,
                                            top: position[
                                                SizeDefaultsFields
                                                    .BASE_TOP_MARGIN
                                            ].lineY,
                                            left: position[
                                                SizeDefaultsFields
                                                    .BASE_TOP_MARGIN
                                            ].lineX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.BASE_TOP_MARGIN
                                                    ? 0.6
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields
                                                    .BASE_TOP_MARGIN
                                            ].arrowWidth,
                                            transform: 'rotate(180deg)',
                                            top: position[
                                                SizeDefaultsFields
                                                    .BASE_TOP_MARGIN
                                            ].arrowY,
                                            left: position[
                                                SizeDefaultsFields
                                                    .BASE_TOP_MARGIN
                                            ].leftArrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.BASE_TOP_MARGIN
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields
                                                    .BASE_TOP_MARGIN
                                            ].arrowWidth,
                                            top: position[
                                                SizeDefaultsFields
                                                    .BASE_TOP_MARGIN
                                            ].arrowY,
                                            left: position[
                                                SizeDefaultsFields
                                                    .BASE_TOP_MARGIN
                                            ].rightArrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.BASE_TOP_MARGIN
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                </>
                            ) : null}

                            {position.hasOwnProperty(
                                SizeDefaultsFields.BASE_BOTTOM_MARGIN
                            ) ? (
                                <>
                                    <div
                                        className="gap-size-default-line"
                                        style={{
                                            height: '2px',
                                            width: position[
                                                SizeDefaultsFields
                                                    .BASE_BOTTOM_MARGIN
                                            ].lineWidth,
                                            top: position[
                                                SizeDefaultsFields
                                                    .BASE_BOTTOM_MARGIN
                                            ].lineY,
                                            left: position[
                                                SizeDefaultsFields
                                                    .BASE_BOTTOM_MARGIN
                                            ].lineX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.BASE_BOTTOM_MARGIN
                                                    ? 0.6
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields
                                                    .BASE_BOTTOM_MARGIN
                                            ].arrowWidth,
                                            transform: 'rotate(180deg)',
                                            top: position[
                                                SizeDefaultsFields
                                                    .BASE_BOTTOM_MARGIN
                                            ].arrowY,
                                            left: position[
                                                SizeDefaultsFields
                                                    .BASE_BOTTOM_MARGIN
                                            ].leftArrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.BASE_BOTTOM_MARGIN
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                    <Icon
                                        className="arrow-image"
                                        iconName="Arrow.svg"
                                        style={{
                                            width: position[
                                                SizeDefaultsFields
                                                    .BASE_BOTTOM_MARGIN
                                            ].arrowWidth,
                                            top: position[
                                                SizeDefaultsFields
                                                    .BASE_BOTTOM_MARGIN
                                            ].arrowY,
                                            left: position[
                                                SizeDefaultsFields
                                                    .BASE_BOTTOM_MARGIN
                                            ].rightArrowX,
                                            opacity:
                                                fade ===
                                                SizeDefaultsFields.BASE_BOTTOM_MARGIN
                                                    ? 1
                                                    : 0,
                                        }}
                                    />
                                </>
                            ) : null}
                            <Image
                                id={`${SizeDefaultsFields.BASE_MARGIN}_image_id`}
                                style={{width: '90%'}}
                                src={String(DoubleBase)}
                                alt="Base door gap"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
