import React, {useEffect, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {useDispatchWithFormik} from 'components/customer/Materials/helper/useDispatchMaterialFormik';
import {useConfirmationDialog, Wizard} from 'shared';
import {
    allDataCleared,
    pendingDoorCleared,
} from 'components/customer/Materials/store/materialSlice';
import {pageSet} from 'store/customer/appSlice';
import {Page} from 'store/customer/entity/Page';
import {ActionType} from 'components/customer/Materials/helper/useDispatchMaterial';
import {SupplyDefaults} from 'components/customer/Room/SupplyDefaults';
import {HardwareSelection} from 'components/customer/Room/HardwareSelection';
import ColourSearch from 'components/customer/Room/ColourSearch';
import {ProductSizeDefaults} from 'components/customer/Room/ProductSizeDefaults';
import {GapSizeDefaults} from 'components/customer/Room/GapSizeDefaults';
import {AdvancedDoors} from 'components/customer/Room/AdvancedDoors';
import {
    getDoor,
    getPendingDoor,
} from 'components/customer/Materials/store/selectors/doorSelector';
import {FormikErrors} from 'formik';
import {shallowEqual} from 'react-redux';
import {Room} from 'components/customer/Room/entity/Room';

interface RoomComponentsProps {
    currentTab: number;
    setCurrentTab: (currentTab: number) => void;
    values: Room;
    errors: FormikErrors<Room>;
    isMiniEditor?: boolean;
    showTabNavs?: number[];
}

const RoomComponents = ({
    currentTab,
    setCurrentTab,
    values,
    isMiniEditor,
    showTabNavs,
}: RoomComponentsProps) => {
    const dispatch = useAppDispatch();

    const pendingDoor = useAppSelector(getPendingDoor, shallowEqual);
    const door = useAppSelector(getDoor, shallowEqual);

    const materialDispatch = useDispatchWithFormik({page: 0, index: 0});

    const {dialog: doorAlert, showDialog} = useConfirmationDialog();

    useEffect(() => {
        // NOTE: 0 is first item in enum.
        // Change it to enum when this file is converted to .TS
        dispatch(pageSet(Page.ROOM));

        return () => {
            dispatch(pageSet(Page.PRODUCT));
            dispatch(allDataCleared());
        };
    }, []);

    useEffect(() => {
        if (pendingDoor) {
            showDialog({
                title: 'Room border changed',
                message:
                    'This selection will change the room border values. Are you sure you want to continue?',
                yes: () => {
                    materialDispatch(ActionType.Door, pendingDoor);
                    dispatch(pendingDoorCleared({}, 0));
                },
                no: () => {
                    materialDispatch(ActionType.Door, {...door});
                    dispatch(pendingDoorCleared({}, 0));
                },
            });
        }
    }, [pendingDoor]);

    const wizard = useMemo(() => {
        return (
            <>
                {doorAlert}
                <Wizard
                    showNav={showTabNavs}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    className="room-form"
                    lazy={true}>
                    <SupplyDefaults
                        key={0}
                        title="Supply Defaults"
                        eventKey={0}
                        noPadding={true}
                    />
                    <HardwareSelection
                        key={1}
                        title="Hardware Selections"
                        eventKey={1}
                        isMiniEditor={isMiniEditor}
                    />
                    <ColourSearch
                        key={2}
                        materialType={0}
                        title={
                            isMiniEditor
                                ? 'Exterior Colour'
                                : 'Exterior Colour Selections'
                        }
                        eventKey={2}
                        materialPage={0}
                        isMiniEditor={isMiniEditor}
                    />
                    <ColourSearch
                        key={3}
                        materialType={1}
                        title={
                            isMiniEditor
                                ? 'Carcase Colour'
                                : 'Carcase Colour Selections'
                        }
                        eventKey={3}
                        materialPage={0}
                        isMiniEditor={isMiniEditor}
                    />
                    <ProductSizeDefaults
                        key={4}
                        title="Product Size Defaults (OPTIONAL)"
                        eventKey={4}
                    />
                    <GapSizeDefaults
                        key={5}
                        title="Gap Size Defaults (OPTIONAL)"
                        eventKey={5}
                        noPadding={true}
                    />
                    <AdvancedDoors
                        key={6}
                        title="Advanced Doors"
                        eventKey={6}
                        hide={!values.isAdvancedDoor}
                    />
                </Wizard>
            </>
        );
    }, [doorAlert, currentTab, values.isAdvancedDoor]);

    return wizard;
};

export default RoomComponents;
