import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import {useGetDrawerSystemQuery} from 'components/customer/Product/store/drawerRunnerApi';
import {
    useGetCabinetTopsQuery,
    useGetHingeStylesRoomQuery,
} from 'components/customer/Room/store/roomApi';

export const useHardwareSelection = () => {
    const {data: userProfile} = useGetUserQuery();
    const {
        data: hingeStyles,
        isLoading: loadingHingeStyles,
        isFetching: fetchingHingeStyles,
    } = useGetHingeStylesRoomQuery();
    const {
        data: cabinetTops,
        isLoading: loadingCabinetTops,
        isFetching: fetchingCabinetTops,
    } = useGetCabinetTopsQuery();
    const {
        data: drawerSystems,
        isLoading: loadingDrawerSystems,
        isFetching: fetchingDrawerSystems,
    } = useGetDrawerSystemQuery();

    return {
        userProfile,
        hingeStyles,
        loadingHingeStyles: loadingHingeStyles || fetchingHingeStyles,
        cabinetTops,
        loadingCabinetTops: loadingCabinetTops || fetchingCabinetTops,
        drawerSystems,
        loadingDrawerSystems: loadingDrawerSystems || fetchingDrawerSystems,
    };
};
