import {string, ObjectSchema} from 'yup';
import {useAppSelector} from 'store/customer';
import {MaterialType} from 'components/customer/Materials/entity';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {initialValues} from 'components/customer/Room/helpers/Room';

const useCustomColourValidationSchema = () => {
    const exteriorMaterial = useAppSelector((state) =>
        getMaterial(state, MaterialType.EXTERIOR)
    );
    const carcaseMaterial = useAppSelector((state) =>
        getMaterial(state, MaterialType.CARCASE)
    );

    const addCustomColourValidation = (
        roomSchema: ObjectSchema<typeof initialValues>
    ) => {
        if (exteriorMaterial?.is_custom_colour)
            roomSchema = roomSchema.shape({
                exteriorColorCustomName: string().test(
                    'requiredExteriorCustomColour',
                    'Please enter custom exterior colour name',
                    (colour) => colour && colour?.trim() != ''
                ),
            });

        if (carcaseMaterial?.is_custom_colour)
            roomSchema = roomSchema.shape({
                carcaseColorCustomName: string().test(
                    'requiredCarcaseCustomColour',
                    'Please enter custom carcase colour name',
                    (colour) => colour && colour?.trim() != ''
                ),
            });

        return roomSchema;
    };

    return {
        addCustomColourValidation,
    };
};

export default useCustomColourValidationSchema;
