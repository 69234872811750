import React, {useCallback, useMemo} from 'react';
import {
    Col,
    Form,
    FormControl,
    InputGroup,
    OverlayTrigger,
    Tooltip,
    TooltipProps,
} from 'react-bootstrap';
import {MoreInfo} from 'shared';
import styled from 'styled-components';
import {useField} from 'formik';
import {Dropdown, DropdownOption} from 'shared/components/Forms/Dropdown';
import {useTabletSize} from 'shared/helpers/DeviceSize';
import {FormGroup} from 'shared/components/Forms/FormGroup';

export enum SizeDefaultsFields {
    TOE_KICK_HEIGHT = 'toe_kick_height',
    SHELF_SETBACK = 'shelf_setback',
    BASE_CARCASE_HEIGHT = 'base_carcase_height',
    BASE_CARCASE_DEPTH = 'base_carcase_depth',
    UPPER_CARCASE_HEIGHT = 'upper_carcase_height',
    UPPER_CARCASE_DEPTH = 'upper_carcase_depth',
    TALL_CARCASE_HEIGHT = 'tall_carcase_height',
    TALL_CARCASE_DEPTH = 'tall_carcase_depth',

    DOOR_GAP = 'doorGap',
    DRAWER_GAP = 'drawerGap',
    GAP = 'gap',
    LEFT_GAP = 'left_gap',
    RIGHT_GAP = 'right_gap',
    UPPER_MARGIN = 'upper_margin',
    UPPER_TOP_MARGIN = 'upper_top_margin',
    UPPER_BOTTOM_MARGIN = 'upper_bottom_margin',
    BASE_MARGIN = 'base_margin',
    BASE_TOP_MARGIN = 'base_top_margin',
    BASE_BOTTOM_MARGIN = 'base_bottom_margin',
}

export enum FieldType {
    DROPDOWN = 'dropdown',
    NUMBER = 'number',
}

interface ProductSizeDefaultFieldProps {
    label: string;
    name: string;
    field?: SizeDefaultsFields;
    selectedField?: SizeDefaultsFields;
    moreInfo?: number;
    tabIndex?: number;
    setField?: (field: SizeDefaultsFields) => void;
    labelSpan?: {
        offset?: number;
        span?: number;
    };
    inputSpan?: {
        offset?: number;
        span?: number;
    };
    type?: FieldType;
    options?: DropdownOption[];
}

const defaultLabelSpan = {
    offset: 1,
    span: 4,
};

const defaultInputSpan = {
    offset: 1,
    span: 5,
};

const defaultOptions = [] as DropdownOption[];

export const SizeDefaultField = ({
    label,
    name,
    moreInfo,
    tabIndex,
    field,
    selectedField,
    setField,
    labelSpan = defaultLabelSpan,
    inputSpan = defaultInputSpan,
    type = FieldType.NUMBER,
    options = defaultOptions,
}: ProductSizeDefaultFieldProps) => {
    const [formikField, meta, {setValue}] = useField(name);
    const isTabletSize = useTabletSize();

    const onFocusHandler = useCallback(() => {
        if (field && typeof setField === 'function') {
            setField(field);
        }
    }, [field]);

    const syntheticOnChange = useCallback(
        (name: string, value: number) => {
            if (type == FieldType.DROPDOWN) {
                void setValue(value);
            }
        },
        [type, formikField]
    );

    const renderToolTip = useCallback(
        (props: TooltipProps) => {
            return (
                <Tooltip id={name} {...props} className="error-popover">
                    {meta.error}
                </Tooltip>
            );
        },
        [meta, name]
    );

    const inputFieldWithError = useMemo(() => {
        const showError = !!meta.error;
        const inputField = (
            <Input
                {...formikField}
                tabIndex={tabIndex}
                onFocus={onFocusHandler}
                data-cy={name}
                autoComplete="off"
                isInvalid={showError}
            />
        );

        return (
            <>
                {showError ? (
                    <OverlayTrigger placement="right" overlay={renderToolTip}>
                        {inputField}
                    </OverlayTrigger>
                ) : (
                    inputField
                )}
                <InputAppend>mm</InputAppend>
            </>
        );
    }, [meta, formikField, tabIndex, name]);

    return (
        <FormGroup>
            <Form.Label
                column
                md={isTabletSize ? labelSpan.span : labelSpan}
                xs={6}
                className="thin">
                <Label $active={field == selectedField}>{label}:</Label>

                {typeof moreInfo !== 'undefined' ? (
                    <MoreInfo info={moreInfo} />
                ) : null}
            </Form.Label>
            <Col md={isTabletSize ? inputSpan.span : inputSpan} xs={6}>
                {type == FieldType.NUMBER ? (
                    <InputGroup className="cbc-size">
                        {inputFieldWithError}
                    </InputGroup>
                ) : (
                    <Dropdown
                        {...formikField}
                        selectHandler={syntheticOnChange}
                        options={options}
                        name={name}
                        placeholder="Loading..."
                    />
                )}
            </Col>
        </FormGroup>
    );
};

const Label = styled.label<{$active: boolean}>`
    font-weight: ${({$active}) => ($active ? '700' : '500')} !important;
    font-size: 1em !important;
    padding-right: 10px;
`;

const Input = styled(FormControl)<{$active: boolean}>`
    box-shadow: ${({$active}) =>
        $active ? '0 0 0 0.2rem rgba(0, 123, 255, .25)' : 'none'};
`;

const InputAppend = styled(InputGroup.Text)`
    color: #6b6f70;
    font-size: 0.85em;
    font-weight: 500;
    padding: 0 8px 2px;
    background: #fff;
    border: 0;
`;
